/** @format */

import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	Grid,
	makeStyles,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	Card,
	CardContent,
	IconButton,
	CardMedia,
	withStyles,
	ButtonGroup,
	Snackbar,
	CardActions,
	DialogTitle,
	Divider,
	Tooltip,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { ThumbDown, ThumbUp, Star, Share } from "@material-ui/icons";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendFeedback } from "../../actions/bot";
import { IFeedback } from "../../models/feedback";
import { useTypedSelector } from "../../reducers/index";
import icon_EMSD1 from "../../assets/icons/icon_EMSD1.png";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { appendMessageToMessagesList, feedbackAvailable, feedbackSent, emptyMessages, getMessages } from "../../actions/message";
import { MediaType } from "../../models/message";
import { useParams, Link } from "react-router-dom";
import { IClarifyingQuestion } from "../../models/clarifyingQuestion";
import * as Yup from "yup";
import Cookies from "universal-cookie";

import { Alert, Rating, IconContainerProps } from "@material-ui/lab";
import { ActionStatus } from "../../actions/status";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import ShareIcon from "@material-ui/icons/Share";
interface IFeedbackProps {
	feedback: IFeedback;

	displayIcon: boolean;

	share: ()=> any;
}

const useStyles = makeStyles({
	myTextBubble: {
		backgroundColor: "#ffffff",
		maxWidth: "280px",
		wordBreak: "break-word",
		paddingTop: 5,
		"&:last-child": {},
	},
	rightArrow: {
		display: "flex",
		"&:after": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #ffffff",
			borderRight: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	leftArrow: {
		display: "flex",
		"&:before": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #35d8f8",
			borderLeft: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	otherTextBubble: {
		backgroundColor: "#35d8f8",
		maxWidth: "264px",
		wordBreak: "break-word",
		paddingTop: 8,
		"&:last-child": {
			paddingBottom: 8,
		},
	},
	leftCard: {
		borderRadius: "0 2px 2px 2px",
	},
	rightCard: {
		borderRadius: "2px 0 2px 2px",
	},
	card: {
		borderRadius: "2px",
		maxWidth: "280px",
	},
	nameAgent: {},
	avatar: {
		height: "40px",
		width: "40px",
	},
	dialog: {
		backgroundColor: "#35d8f8",
		minWidth: "280px",
	},
	text: {
		backgroundColor: "white",
		padding: "10px",
		width: "250px",
	},
	send: {
		marginTop: "5px",
		backgroundColor: "#0089ea",
		color: "black",
		textTransform: "none",
	},
	button: {
		backgroundColor: "#35d8f8",
		color: "white",
	},
	radioGroup: {
		display: "inline",
		marginLeft: "10px",
		// flexDirection: "row",
	},
	iconColor: {
		color: "white",
	},
	label: {
		// marginLeft: "auto",
		// marginRight: "auto",
	},
	a: {
		wordBreak: "break-word",
		display: "inline",
	},
	icon: {
		borderRadius: "50%",
		width: 16,
		height: 16,
		boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
		backgroundColor: "#f5f8fa",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		"$root.Mui-focusVisible &": {
			outline: "2px auto rgba(19,124,189,.6)",
			outlineOffset: 2,
		},
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5",
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)",
		},
	},
	checkedIcon: {
		backgroundColor: "#137cbd",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			width: 16,
			height: 16,
			backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
			content: '""',
		},
		"input:hover ~ &": {
			backgroundColor: "#106ba3",
		},
	},
	dialogContent: {
		display: "flex",
		flexDirection: "column",
	},
	rootButton: {
		padding: "0px",
		minWidth: "50px !important",
	},

	snack: { zIndex: 99999999 },
	// accordian: {
	// 	border: "1px solid rgba(0, 0, 0, .125)",
	// 	boxShadow: "none",
	// 	"&:not(:last-child)": {
	// 		borderBottom: 0,
	// 	},
	// 	"&:before": {
	// 		display: "none",
	// 	},
	// 	"&$expanded": {
	// 		margin: "auto",
	// 	},
	// },
	// accordianSummary: {
	// 	backgroundColor: "rgba(0, 0, 0, .03)",
	// 	borderBottom: "1px solid rgba(0, 0, 0, .125)",
	// 	marginBottom: -1,
	// 	minHeight: 56,
	// 	"&$expanded": {
	// 		minHeight: 56,
	// 	},
	// },
	// accordianDetails: {
	// 	padding: theme.spacing(2),
	// },
});

const Accordion = withStyles({
	root: {
		padding: 0,
		//marginLeft: "50px",
		maxWidth: "296px",
		border: "1px solid rgba(0, 0, 0, .125)",
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
		"&$expanded": {
			//	margin: "auto",
			margin: 0,
			//marginLeft: "50px",
		},
	},
	expanded: {
		paddingTop: "0px",
		paddingBottom: "0px",
	},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		//backgroundColor: "rgba(0, 0, 0, .03)",
		borderBottom: "1px solid rgba(0, 0, 0, .125)",
		//marginBottom: -1,
		minHeight: 17,
		//  margin:"5px",
		padding: "5px",
		"&$expanded": {
			minHeight: 17,
			// margin:"5px"
		},
		"&:nth-child(1)": {
			borderRadius: "2px 2px 0px 0px",
		},
		"&:nth-child(3)": {
			borderRadius: "0px 0px 2px 2px",
		},

		//backgroundColor: "#35D8F8",
		backgroundColor: "#9decfb",
	},
	content: {
		"&$expanded": {
			// margin: "12px 0",
			margin: "5px",
			padding: "0px",
		},
		minHeight: 17,
		margin: "5px",
		// padding:"5px"
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		// padding: theme.spacing(2),
		paddingTop: "5px",
		paddingBottom: "5px",
	},
}))(MuiAccordionDetails);

const customIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
	1: {
		icon: (
			<div style={{ display: "block" }}>
				<Star />
				<Typography
					style={{
						fontSize: "10px",
						justifyContent: "center",
						display: "flex",
						alignItems: "center",
					}}>
					1
				</Typography>
			</div>
		),
		label: "Very Dissatisfied",
	},
	2: {
		icon: (
			<div style={{ display: "block" }}>
				<Star />
				<Typography
					style={{
						fontSize: "10px",
						justifyContent: "center",
						display: "flex",
						alignItems: "center",
					}}>
					2
				</Typography>
			</div>
		),
		label: "Dissatisfied",
	},
	3: {
		icon: (
			<div style={{ display: "block" }}>
				<Star />
				<Typography
					style={{
						fontSize: "10px",
						justifyContent: "center",
						display: "flex",
						alignItems: "center",
					}}>
					3
				</Typography>
			</div>
		),
		label: "Neutral",
	},
	4: {
		icon: (
			<div style={{ display: "block" }}>
				<Star />
				<Typography
					style={{
						fontSize: "10px",
						justifyContent: "center",
						display: "flex",
						alignItems: "center",
					}}>
					4
				</Typography>
			</div>
		),
		label: "Satisfied",
	},
};

function IconContainer(props: IconContainerProps) {
	const { value, ...other } = props;
	return <span {...other}>{customIcons[value].icon}</span>;
}
export const Feedback: React.FC<IFeedbackProps> = (props) => {
	const classes = useStyles();
	const userId = useTypedSelector((state) => state.auth.id);
	const [feedbackStatus, setFeedbackStatus] = useState(false);
	const { t } = useTranslation();
	const isRight = !props.feedback.userId || props.feedback.userId === userId;
	const { id } = useParams();
	const [relatedQuestions, setRelatedQuestions] = useState(false);
	const [filteredContent, setFilteredContent] = useState([] as string[]);
	const [open, setOpen] = useState(false);
	// const status = useTypedSelector((state) => state.bot.sendImageFeedbackStatus);
	const [disableAgent, setDisableAgent] = React.useState(false);

	const [received, setReceived] = useState(false);
	const [isPositive, setPositive] = useState(true);
	const dispatch = useDispatch();

	const cookies = new Cookies();

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPositive(!isPositive);
	};
	const [expanded, setExpanded] = React.useState<string | false>("panel4");
	const [rating, setRating] = React.useState(0 as any);
	const [timer, setTimer] = useState<number>();
	const status = useTypedSelector((state) => state.bot.sendFeedbackStatus[props.feedback.feedbackId]);

	const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleFilterContent = (content: string) => {
		const regex = /(?<url><url="[^\s]*">[^>]+?<\/url>)|(?<pic><pic>.+?<\/pic>)|(?<linebreak><br(\/)?>)|[^\<\n]+/gm;
	
		const matches = content.toString().matchAll(regex);

		const orderedMatches = [] as string[];

		for (let match of matches as any) {
			orderedMatches.push(match[0]);
		}
		return orderedMatches;
	};

	useEffect(() => {
		setFilteredContent(handleFilterContent(props.feedback.content.replace(/(\r\n|\n|\r)/gm, "")));
		// dispatch(feedbackAvailable());
	}, []);

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setReceived(false);
	};

	

	return (
		<Grid container item spacing={2} direction={isRight ? "row-reverse" : "row"}>
			<Grid item style={{ paddingLeft: 0, marginLeft: 0, paddingRight: 0, marginRight: 0 }}>
				{props.displayIcon !== false && isRight === false ? (
					<Box
						mt={0.5}
						style={{
							textAlign: "center",
						}}>
						<Avatar classes={{ img: classes.avatar }} src={icon_EMSD1} />
						<Typography variant="caption" className={classes.nameAgent}>
							{t("nameVersion")}
						</Typography>
					</Box>
				) : props.displayIcon !== false ? (
					<Box mt={0.5} />
				) : (
					<Box width="0.5rem" height="2.5rem" />
				)}
			</Grid>
			<Grid item style={{ paddingRight: 0, marginRight: 0, paddingLeft: 0, marginLeft: 0 }}>
				<Grid container spacing={1} direction="column">
					<Grid item>
						<Box className={isRight && props.displayIcon ? classes.rightArrow : props.displayIcon ? classes.leftArrow : classes.card}>
							<Card
								elevation={0}
								className={isRight && props.displayIcon ? classes.rightCard : props.displayIcon ? classes.leftCard : classes.card}>
								<CardContent className={isRight ? classes.myTextBubble : classes.otherTextBubble}>
									{/* <Typography>{props.feedback.content}</Typography> */}
									{filteredContent.map((contentPart: string) => {
										if (contentPart.includes("<br>" || "<br/>")) {
											return <br></br>;
										} else if (contentPart.includes("<url=")) {
											// substring last bit, and first bit  put last bit as a href linking to first bit
											const urlRegex = /<url="([^\s]*)">([^>]+?)<\/url>/gm;
											const matches = contentPart
												.toString()
												.replace(/(\r\n|\n|\r)/gm, "")
												.matchAll(urlRegex);
											const array = Array.from(matches);
											//console.log(array[0]);
											return (
												<a
													style={{ lineHeight: "normal", fontSize: "13px" }}
													className={classes.a}
													href={array[0][1]}
													// style={{display: "table-cell"}}
													target="_blank"
													rel="noopener noreferrer">
													{array[0][2]}
												</a>
											);
										} else if (contentPart.includes("<pic>")) {
											//substring first bit and last
											const imageServer = process.env.REACT_APP_IMAGE_FILE_SERVER;
											const image = `${imageServer}/${contentPart.substr(5).slice(0, -6)}.jpg`;
											//console.log(image);
											return (
												<a
													href={image}
													// style={{display: "table-cell"}}
													target="_blank"
													rel="noopener noreferrer">
													<CardMedia style={{ height: `300px`, width: `300px` }} image={image} />
												</a>
											);
										} else
											return (
												<Typography style={{ display: "inline", wordBreak: "break-word", lineHeight: "normal", fontSize: "13px" }}>
													{contentPart}
												</Typography>
											);
									})}
									<br />
									<br />
								</CardContent>

								<CardActions
									style={{
										justifyContent: "center",
										display: "flex",
										alignItems: "center",
										backgroundColor: "#D6EAF8",
										borderTop: "1px solid rgba(0, 0, 0, .205)",
										padding: "0px",
										wordBreak: "break-word",
									}}>
									{/* // <ButtonGroup variant="contained" color="primary" style={{marginLeft:"12px"}} aria-label="contained primary button group"> */}
									{props.feedback.options.length ? (
										<Button
											size="small"
											style={{
												whiteSpace: "nowrap",
												textTransform: "none",
												// maxWidth: "150px",
												display: "inline-block",
												marginLeft: "auto",
												// marginRight:"auto",
											}}
											// variant="contained"
											color="primary"
											disableElevation
											disabled={relatedQuestions}
											onClick={(e) => {
												setRelatedQuestions(true);
											}}>
											{t(`related`)}
										</Button>
									) : null}
									<Divider orientation="vertical" flexItem style={{ marginLeft: "auto" }} />
									<Tooltip title="Share">
										<IconButton
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												marginLeft: "0px",
												backgroundColor: "transparent",
											}}
											onClick={()=>{props.share()}}
											>
												
											<ShareIcon style={{ color: "#193366" }} />
										</IconButton>
									</Tooltip>
								</CardActions>

								<CardActions
									style={{
										justifyContent: "center",
										display: "flex",
										alignItems: "center",
										backgroundColor: "#D6EAF8",
										borderTop: "1px solid rgba(0, 0, 0, .205)",
										wordBreak: "break-word",
										marginLeft: "0px",
										maxWidth: "280px",
										width: "100%",
									}}>
									{feedbackStatus ? (
										<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
											<>
												<Typography variant="caption">{t(`postsubmit`)}</Typography>
												<Typography variant="caption" align="center" style={{ fontSize: "10px" }}>
													{t(`furtherQuestion`)}
													{/* {t(`furtherQuestion2`)} */}
												</Typography>
												{/* <Typography variant="caption" style={{ fontSize: "10px" }}>
													{t(`furtherQuestion2`)}
												</Typography> */}
											</>
										</div>
									) : (
										<>
											<Typography variant="caption" style={{ paddingRight: "0px" }}>
												{t(`helpfulQ`)}
											</Typography>

											{/* <Button
												onClick={(e) => {
													setPositive(true);
													setOpen(true);
												}}>
												<ThumbUp color={isPositive ? "primary" : "disabled"} fontSize="small" />
											</Button>
											<Button
												onClick={(e) => {
													setPositive(false);
													setOpen(true);
												}}>
												<ThumbDown color={!isPositive ? "secondary" : "disabled"} fontSize="small" />
											</Button> */}
											<Grid container spacing={0}>
												<Grid item xs={2} style={{ height: "100%", justifyContent: "center", display: "flex", alignItems: "center" }}>
													<SentimentVeryDissatisfiedIcon style={{ paddingTop: "10px" }} />
												</Grid>
												<Grid
													item
													xs={8}
													style={{
														height: "100%",
														justifyContent: "center",
														display: "flex",
														alignItems: "center",
														fontSize: "10px",
													}}>
													{/* <Grid container spacing={0}>
														<Grid item xs={12}> */}
													{/* <Box component="fieldset" borderColor="transparent"> */}
													<Rating
														// name={uuid()}
														onChange={(e, value) => {
															// console.log(value)
															// setRating(value)
															setRating(value);
															// clearTimeout(timer);

															// setTimer(
															// 	(setTimeout(() => {
															// 		setReceived(true);
															// 		setFeedbackStatus(true);
															// 		dispatch(
															// 			sendFeedback({
															// 				id: props.feedback.feedbackId,
															// 				username: "",
															// 				isPositive,
															// 				rating: value || 0,
															// 			}),
															// 		);
															// 	}, 1000) as unknown) as number,
															// );
														}}
														defaultValue={0}
														value={rating}
														// size="large"
														max={4}
														// icon={[
														// 	<div style={{ display: "block" }}>
														// 		<Star />
														// 		<Typography
														// 			style={{
														// 				fontSize: "10px",
														// 				justifyContent: "center",
														// 				display: "flex",
														// 				alignItems: "center",
														// 			}}>
														// 			1
														// 		</Typography>
														// 	</div>,
														// ]}
														IconContainerComponent={IconContainer}
													/>
													{/* </Box> */}
												</Grid>
												{/* <Grid item xs={3}>
															<Typography
																variant="caption"
																style={{ justifyContent: "center", display: "flex", alignItems: "center", fontSize: "10px" }}
																display="inline">
																1
															</Typography>
														</Grid>
														<Grid item xs={3} >
															<Typography
																variant="caption"
																style={{ justifyContent: "center", display: "flex", alignItems: "center", fontSize: "10px" }}
																display="inline">
																2
															</Typography>
														</Grid>
														<Grid item xs={3} >
															<Typography
																variant="caption"
																style={{ justifyContent: "center", display: "flex", alignItems: "center", fontSize: "10px" }}
																display="inline">
																3
															</Typography>
														</Grid>
														<Grid item xs={3} >
															<Typography
																variant="caption"
																style={{ justifyContent: "center", display: "flex", alignItems: "center", fontSize: "10px" }}
																display="inline">
																4
															</Typography>
														</Grid> */}
												{/* </Grid>
												</Grid> */}
												<Grid item xs={2} style={{ height: "100%", justifyContent: "center", display: "flex", alignItems: "center" }}>
													<SentimentSatisfiedAltIcon style={{ paddingTop: "10px" }} />
												</Grid>
											</Grid>
											<Button
												classes={{ root: classes.rootButton }}
												disabled={rating == 0}
												style={{
													justifyContent: "center",
													display: "flex",
													alignItems: "center",
													backgroundColor: "rgba(0, 91, 255, 0.46)",
												}}
												onClick={() => {
													setReceived(true);
													setFeedbackStatus(true);
													dispatch(
														sendFeedback({
															id: props.feedback.feedbackId,
															username: "",
															isPositive: rating > 2 ? true : false,
															rating: rating,
														}),
													);
												}}>
												{t(`send`)}
											</Button>

											{/* <Typography variant="caption" style={{ fontSize: "10px" }} display="inline">
												{t(`furtherQuestion`)}
												{t(`furtherQuestion2`)}
											</Typography> */}
										</>
									)}
								</CardActions>
								{/* {open && !feedbackStatus ? (
									<CardActions
										style={{
											justifyContent: "center",
											display: "flex",
											alignItems: "center",
											backgroundColor: "#D6EAF8",
											borderTop: "1px solid rgba(0, 0, 0, .205)",
											flexDirection: "column",
											wordBreak: "break-word",
											maxWidth: "361px",
											width: "100%",
										}}>
										<Box component="fieldset" borderColor="transparent">
											<Rating
												onChange={(e, value) => {
													// console.log(value)
													// setRating(value)
													clearTimeout(timer);
													setTimer(
														(setTimeout(() => {
															setReceived(true);
															setFeedbackStatus(true);
															dispatch(
																sendFeedback({
																	id: props.feedback.feedbackId,
																	username: "",
																	isPositive,
																	rating: value || 0,
																}),
															);
														}, 1000) as unknown) as number,
													);
												}}
												name="size-large"
												defaultValue={0}
												size="large"
												max={4}
											/>
										</Box>
										<Typography variant="caption" style={{ fontSize: "10px" }} display="inline">
											{t(`furtherQuestion`)}
											{t(`furtherQuestion2`)}
										</Typography>
										<Typography variant="caption" style={{ fontSize: "10px" }} display="inline"></Typography>
									</CardActions>
								) : null} */}
							</Card>
						</Box>
					</Grid>
					<Grid item>
						{relatedQuestions ? (
							<>
								{props.feedback.options.map((option, index) => (
									<Accordion
										key={option.question + option.answer}
										//  expanded={expanded === `panel${index}`}
										onChange={handleChange(`panel${index}`)}
										style={{
											// marginTop: "10px",
											marginLeft: "12px",
										}}>
										<AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
											<Typography style={{ fontSize: "13px", lineHeight: "normal" }}>{option.question}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>
												{handleFilterContent(option.answer).map((contentPart: string) => {
													if (contentPart.includes("<br>" || "<br/>")) {
														return <br></br>;
													} else if (contentPart.includes("<url=")) {
														// substring last bit, and first bit  put last bit as a href linking to first bit
														const urlRegex = /<url="(.*)">(.+?)<\/url>/gm;
														const matches = contentPart.toString().matchAll(urlRegex);
														const array = Array.from(matches);
														//console.log(array[0]);
														return (
															<a
																style={{ display: "inline", fontSize: "13px" }}
																target="_blank"
																rel="noopener noreferrer"
																className={classes.a}
																href={array[0][1]}>
																{array[0][2]}
															</a>
														);
													} else if (contentPart.includes("<pic>")) {
														//substring first bit and last
														const imageServer = process.env.REACT_APP_IMAGE_FILE_SERVER;
														const image = `${imageServer}/${contentPart.substr(5).slice(0, -6)}.jpg`;
														//console.log(image);
														return (
															<a href={image} style={{ display: "table-cell" }} target="_blank" rel="noopener noreferrer">
																<CardMedia style={{ height: `300px`, width: `300px` }} image={image} />
															</a>
														);
													} else
														return (
															<Typography
																style={{ fontSize: "13px", display: "inline", wordBreak: "break-word", lineHeight: "normal" }}>
																{contentPart}
															</Typography>
														);
												})}
												<br />
											</Typography>
										</AccordionDetails>
									</Accordion>
								))}
							</>
						) : null}
					</Grid>
				</Grid>
			</Grid>
			{status === ActionStatus.SUCCESS && (
				<Snackbar open={received} autoHideDuration={2000} onClose={handleClose} className={classes.snack}>
					<Alert onClose={handleClose} severity="success">
						{t(`received2`)}
					</Alert>
				</Snackbar>
			)}
		</Grid>
	);
};
