import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reducers } from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import saga from '../sagas';

export default function configureStore() {

	const sagaMiddleware = createSagaMiddleware();

	const store = createStore(
		reducers,
		composeWithDevTools(
			applyMiddleware(sagaMiddleware),
		),
	);

	sagaMiddleware.run(saga);

	return store;
}