/** @format */

import { createReducer } from "redux-act";
import { ActionStatus } from "./../actions/status";
import {
	addUserPending,
	addUserSuccess,
	addUserError,
} from "../actions/user";

export interface IUserState {

	addUserStatus: ActionStatus;


}

const initialState: IUserState = {

	addUserStatus: ActionStatus.INITIAL,


};

export const user = createReducer<IUserState>({}, initialState);

user.on(addUserPending, (state) => ({
	...state,
	addUserStatus: ActionStatus.PENDING,
}));

user.on(addUserSuccess, (state) => ({
	...state,
	addUserStatus: ActionStatus.SUCCESS,
}));

user.on(addUserError, (state) => ({
	...state,
	addUserStatus: ActionStatus.ERROR,
}));