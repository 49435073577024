import { setSessionId, respawnSession } from './../actions/session';
// import { loginSuccess } from './../actions/agent';
import { createReducer } from "redux-act";
import { addUserSuccess } from "../actions/user";

export interface IAuthState {

	isAuthenticated: boolean;

	id: string;

	name: string;

	userType: 'user' | 'agent' | null;

}

const initialState: IAuthState = {
	isAuthenticated: false,
	id: '',
	name: '',
	userType: null,
}

export const auth = createReducer<IAuthState>({}, initialState);

auth.on(addUserSuccess, (state, action) => ({
	...state,
	isAuthenticated: true,
	id: action.id,
	name: action.name,
	userType: 'user',
}));

// auth.on(loginSuccess, (state, action) => ({
// 	...state,
// 	isAuthenticated: true,
// 	id: action.id,
// 	name: action.name,
// 	userType: 'agent',
// }));

auth.on(setSessionId, (state, action) => ({
	...state,
	id: action.id,
}));

auth.on(respawnSession, (state, action) => ({
	...state,
	id: action.id,
	userType: action.userType === 'guest' ? null : action.userType,
}));
