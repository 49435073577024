import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LinkHome } from '../layout/LinkHome';
import { Maintenance } from '../layout/Maintenance';
import { UserAuth } from '../layout/UserAuth';
import { UserHome } from '../layout/UserHome';

interface IRoutesProps {}

export const Routes: React.FC<IRoutesProps> = (props) => {

	return (
		<Switch>
			<Route path="/link" component={LinkHome} />
			<Route path="/en/chat/:id" component={UserHome} />
			<Route path="/tc/chat/:id" component={UserHome} />
			<Route path="/sc/chat/:id" component={UserHome} />
			<Route path="/tc" exact component={UserHome} />
			<Route path="/sc" exact component={UserHome} />
			<Route path="/en" exact component={UserHome} />
			<Route path="/en/maintenance" exact component={Maintenance} />
			<Route path="/tc/maintenance" exact component={Maintenance} />
			<Route path="/sc/maintenance" exact component={Maintenance} />

			{/* <Route path="/chat/:id" component={UserHome} />
			 <Route path="/" component={UserHome} /> */}

			{/* <Redirect from="/admin" to="//inquire.chat/admin" /> */}
			{/* <Redirect from="/api" to="//inquire.chat/api" /> */}
		</Switch>
	);
}