import { takeLatest } from 'redux-saga/effects';
/** @format */

import { IFeedback } from "./../models/feedback";
import { IClarifyingQuestion } from "./../models/clarifyingQuestion";
import {
	onAddedClarifyingQuestion,
	onAddedFeedbackRequest,
	sendFeedbackPending,
	sendFeedbackSuccess,
	sendFeedbackError,
	sendComplaintPending,
	sendComplaintSuccess,
	sendComplaintError,
	sendComplaint,

} from "./../actions/bot";
import { Socket as SocketValue } from "socket.io-client";
import { eventChannel } from "redux-saga";
import { take, put, fork, call } from "redux-saga/effects";
import { sendFeedback } from "./../actions/bot";
import { MediaType } from "../models/message";
import { resetFail } from '../actions/message';

type Socket = typeof SocketValue;

function subscribe(socket: Socket) {
	return eventChannel((emit) => {
		socket.on("clarifyingQuestion", ({ data }: { data: IClarifyingQuestion }) => {
			emit(
				onAddedClarifyingQuestion({
					clarifyingQuestion: {
						...data,
						type: MediaType.CLARIFYING_QUESTION,
					},
				}),
			);
		});

		socket.on("feedback", ({ data }: { data: IFeedback }) => {
			emit(resetFail());
			emit(
				onAddedFeedbackRequest({
					feedback: {
						...data,
						type: MediaType.FEEDBACK,
					},
				}),
			);
		});

		return () => {};
	});
}

function* read(socket: Socket) {
	const channel = yield call(subscribe, socket);

	while (true) {
		const action = yield take(channel);
		yield put(action);
	}
}

function* write(socket: Socket) {

	yield takeLatest(sendFeedback, function* (action) {
		yield put(sendFeedbackPending({ id: action.payload.id }));

		socket.emit("feedbackResponse", action.payload);

		try {
			const id = yield call(async () => {
				return await new Promise((res, rej) => {
					socket.on("feedbackResponse", ({ id, status }: { id: string; status: string }) => {
						socket.off("feedbackResponse");
						if (status === "success") {
							res(id);
						} else {
							rej(id);
						}
					});
				});
			});

			yield put(sendFeedbackSuccess({ id }));
		} catch (id) {
			yield put(sendFeedbackError({ id }));
		}
	});

	yield takeLatest(sendComplaint, function* (action) {
		yield put(sendComplaintPending({ id: action.payload._id }));

		socket.emit("complaintResponse", action.payload);

		try {
			const [id, caseId] = yield call(async () => {
				return await new Promise((res, rej) => {
					socket.on("complaintResponse", ({ id, status, caseId }: { id: string; status: string; caseId:string }) => {
						socket.off("complaintResponse");
						if (status === "success") {
							res([id, caseId]);
						} else {
							rej(id);
						}
					});
				});
			});

			yield put(sendComplaintSuccess({ id, caseId }));
		} catch (id) {
			yield put(sendComplaintError({ id }));
		}
	});
}

export function* handleBotAction(socket: Socket) {
	yield fork(read, socket);
	yield fork(write, socket);
}
