import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './Routes';
import { AccessControl } from '../components/Utils/AccessControl';

interface IRouterProps {}

export const Router: React.FC<IRouterProps> = (props) => {
	return (
		<BrowserRouter>
			<AccessControl/>

			<Routes />
		</BrowserRouter>
	);
}