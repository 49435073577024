import { session, ISessionState } from './session';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { message, IMessageState } from './message';
import { user, IUserState } from './user';
import { auth, IAuthState } from './auth';
import { combineReducers } from "redux";
import { socket, ISocketState } from './socket';
import { bot, IBotState } from './bot';

export const reducers = combineReducers({
	auth,
	user,
	message,
	socket,
	session,
	bot,
});

export const useTypedSelector: TypedUseSelectorHook<{
	auth: IAuthState,
	user: IUserState,
	message: IMessageState,
	socket: ISocketState,
	session: ISessionState,
	bot: IBotState,
}> = useSelector;