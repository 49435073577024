/** @format **/
import Cookies from 'js-cookie';

export const preventBehavior = (e: any) =>  {
	e.preventDefault();
}

export const onTabClose = (e: any) =>  {

	Cookies.remove('sessionId');

	e.preventDefault();
	e.returnValue = '';
	delete e['returnValue'];
	
}
