/** @format */

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../reducers";
import { ActionStatus } from "../../../actions/status";
import { AppBar, Toolbar, makeStyles, Select, MenuItem, InputBase, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import axios from "axios";

interface IChatStatusBarProps {
}

const useStyles = makeStyles({
	barColor: {
		backgroundColor: "#35d8f8",
		//minHeight: "5vh",

		alignItems: "flex-end",
		height: "25px",
	},
	iconColor: {
		color: "white",
	},
	iconButton: {
		padding: 0,
	},
	fontColor: {
		flexGrow: 1,
		color: "black",
	},
	toolBar: {
		minHeight: 0,
	},
	select: {
		height: "10px",
		width: "60px",
		padding: "0px 5px",
		// marginTop:"15px",
	},
	menuItem: {
		"&.Mui-focusVisible": {
			backgroundColor: "#35d8f8"
		}	
	},
	bootstrapRoot: {
		fontSize: 12,
		height: "8px",
		width: "55px",
		marginRight: "20px",
		backgroundColor: "transparent",
		"&:focus": {
			backgroundColor: "transparent",
		},
		marginTop:"5px",
	},
	bootstrapInput: {
		fontSize: 12,
		height: "8px",
		width: "55px",
		backgroundColor: "transparent",
		"&:focus": {
			backgroundColor: "transparent",
			outline:"2px solid black",
			borderRadius:"2px"
		},
		// marginTop:"15px",
	},
	dialog: {
		backgroundColor: "#35d8f8",
		minWidth: "300px",
	},
	text: {
		backgroundColor: "white",
		padding: "10px",
		width: "auto",
	},
	send: {
		marginTop: "5px",
		backgroundColor: "#0089ea",
		color: "black",
		textTransform: "none",
	},
	dialogContent: {
		display: "flex",
		flexDirection: "column",
	},
	textButton: {
		backgroundColor: "transparent",
		color: "black",
		"&:hover": {
			backgroundColor:"transparent",
			color:"red"
		}
	},
	snack: { zIndex: 99999999 },
});

export const ChatStatusBar: React.FC<IChatStatusBarProps> = (props) => {
	const { id } = useParams();
	const classes = useStyles();
	const [language, setLanguage] = useState("zh-HK");
	const userType = useTypedSelector((state) => state.auth.userType);
	const [channel, setChannel] = useState("bot");
	const location = useLocation();
	const { i18n } = useTranslation();
	const [version, setVersion] = React.useState();

	useEffect(() => {
		var url = location.pathname.split('/')[1] && location.pathname.split('/')[1].length === 2 ? location.pathname.split('/')[1] : document.referrer;
		url.includes("en")  ? setLanguage("en-HK") : url.includes("sc") ? setLanguage("zh-CN") : setLanguage("zh-HK")
		// console.log(id)
	}, []);

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [language]);

	    useEffect(() => {
			const result = async () => {
				await axios.get(process.env.REACT_APP_IMAGE_FILE_SERVER + `/version.json`).then((response) => {
					console.log(response)
					if(response && response.data && response.data.current)
					setVersion(response.data.current)
				});
			};
			result();
		}, [version]);

	return (
		<>
		<AppBar position="static" elevation={3} className={classes.barColor}>
				<Toolbar className={classes.toolBar}>
					{ (
						<>
							<Typography style={{fontSize:"12px", marginRight:"10px", marginTop:"5px", color: "black"}}>
								{version?version : 
								"v3.3.1"
								}
								</Typography>
							 <Select
								value={language}
								onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => (setLanguage(e.target.value as string))}
								classes={{
									root: classes.select,
									select: classes.select,
									selectMenu: classes.select,
								}}
								input={
									<InputBase
										classes={{
											root: classes.bootstrapRoot,
											input: classes.bootstrapInput,
										}}
									/>
								}
								inputProps={{
									disableUnderline: true,
									style: {
										marginTop:"15px",
										fontSize: 6,
										height: "10px",
										width: "60px",
										backgroundColor: "transparent",
									},
								}}>
								<MenuItem value={"en-HK"} className={classes.menuItem}>Eng</MenuItem>
								<MenuItem value={"zh-HK"} className={classes.menuItem}>繁體</MenuItem>
								<MenuItem value={"zh-CN"} className={classes.menuItem}>简体</MenuItem>
							</Select>
						</>
					)}
				</Toolbar>
			</AppBar>
		</>
	);
};
