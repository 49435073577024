import React from 'react';
import './App.css';
import { Router } from './routes/Router';
import configureStore from './store/Store';
import { Provider } from 'react-redux';
import { SocketProvider } from './components/Utils/SocketProvider';

export const App = () => {
	return (
		<Provider store={configureStore()}>
			<SocketProvider>
				<Router/>
			</SocketProvider>
		</Provider>
	);
};
