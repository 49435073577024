import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../reducers';
import { useLocation, Redirect } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setMaintenanceEnd } from '../../actions/session';
import "./react-meta-tags.d";
import MetaTags from "react-meta-tags";

interface IAccessControlProps {}

export const AccessControl: React.FC<IAccessControlProps> = (props) => {

	const isAuthenticated = useTypedSelector(state => state.auth.isAuthenticated);
	const userType = useTypedSelector(state => state.auth.userType);
	const location = useLocation();
	const id = useTypedSelector(state => state.auth.id);

	const isRespawnable = useTypedSelector(state => state.session.sessionRespawnable);
	const respawnUserType = useTypedSelector(state => state.session.userType);
	const [language, setLanguage] = React.useState("tc");
	const [maintenance, setMaintenance] = React.useState(false);
	const [data, setData] = useState({} as any);
    const [maint, setMaint] = useState(false);
    const dispatch = useDispatch();
	const [TITLE, setTitle] = React.useState("");
	const [DESCRIPTION, setDescription] = React.useState("");
	const [IMAGE_URL, setImageUrl] = React.useState(process.env.REACT_APP_IMAGE_FILE_SERVER+`/icon_EMSD1.png`); 
	const [isLink, setIsLink] = React.useState(true);

    useEffect(()  => {
      const result = async () => {
        await axios.get(process.env.REACT_APP_IMAGE_FILE_SERVER+`/current.json`).then((response) => {
          setData(response) 
        });
      };
      result();

    }, []);
    useEffect(()  => {
      console.log(data)
      if (data && data.data) {
		  if (data.data.current) {
        setMaint(true)
	  dispatch(setMaintenanceEnd({end:data.data.end}))
		  }
		  else {
			  setMaint(false)
		  }
      }
    }, [data]);

	useEffect(() => {
		// if(location.pathname.split('/')[2] == "maintenance") {
		// 	console.log(location.pathname.split('/')[2])
		// 	setMaintenance(true)
		// }
		// var url = window.location.pathname.split('/')[1];

		var url = location.pathname.split('/')[1];
		// console.log(document.referrer)
		// url.includes("en")  ? setLanguage("en") : url.includes("sc") ? setLanguage("sc") : setLanguage("tc")
				if (url.includes("link")) {
					setIsLink(true);
				} else {
					setIsLink(false);
					if (url.includes("en")) {
						setLanguage("en");
					} else if (url.includes("sc")) {
						setLanguage("sc");
					} else if (url.includes("tc")) {
						setLanguage("tc");
					}
				}
	}, [location]);

	useEffect(()=> {

		if(isAuthenticated) {
			setIsLink(false)
		}
	}, [isAuthenticated])

			useEffect(() => {
				if (Object.fromEntries(new URLSearchParams(location.search))) {

					var linkDetails = Object.fromEntries(
						new URLSearchParams(
							location.search
						),
					);

					if(linkDetails && linkDetails.description && linkDetails.title) {
						//replace tags in regex
						setDescription(linkDetails.description.replace(/XQMX/g, "&").replace(/(<([^>]+)>)/ig, ""));
						setTitle(linkDetails.title.replace(/XQMX/g, "&"));
					}
				}
			}, []);


	return (
		<>
			<MetaTags>
				{TITLE && <title>{TITLE}</title>}
				{DESCRIPTION && <meta name="description" content={DESCRIPTION} />}
				{TITLE && <meta property="og:title" content={TITLE} />}

				{IMAGE_URL && <meta property="og:image" content={IMAGE_URL} />}

				{DESCRIPTION && <meta property="og:description" content={DESCRIPTION} />}

				<meta property="og:image:width" content="800" />
				<meta property="og:image:height" content="600" />
				<meta property="fb:app_id" content="1339592129747249" />
				{/* {console.log(process.env.REACT_APP_DOMAIN.concat(location.pathname).concat(location.search))} */}
				{(TITLE && process.env.REACT_APP_DOMAIN) && <meta property="og:url" content={`${process.env.REACT_APP_DOMAIN.concat(location.pathname).concat(location.search)}`} />}
				<meta property="og:type" content="website" />
			</MetaTags>

				{
				isLink ? (
					<Redirect to={location} />
				) : maint ? (
					// respawnUserType === "user"
					// &&
					<Redirect to={`/${language}/maintenance`} />
				) : // : !location.pathname.startsWith('/service') && <Redirect to={`/service`} />
				isAuthenticated ? (
					//  userType === 'user'
					// 	? !location.pathname.startsWith(`/chat/${id}`)
					// &&
					<Redirect to={`/${language}/chat/${id}`} />
				) : (
					// : !location.pathname.startsWith('/service')
					// && <Redirect to={`/service`} />
					!location.pathname.startsWith("/auth") && <Redirect to={`/${language}`} />
				)
				}
		</>
	);
}