import { IFeedback, IFeedbackResponse, IComplaint } from './../models/feedback';
import { createAction } from "redux-act";
import { IClarifyingQuestion } from './../models/clarifyingQuestion';

// listen
export const onAddedClarifyingQuestion = createAction<{
	clarifyingQuestion: IClarifyingQuestion,
}>('onAddedClarifyingQuestion');

export const onAddedFeedbackRequest = createAction<{
	feedback: IFeedback,
}>('onAddedFeedbackRequest');

// emit
export const sendFeedback = createAction<IFeedbackResponse>('sendFeedback');

export const sendFeedbackPending = createAction<{
	id: string,
}>('sendFeedback_pending');

export const sendFeedbackSuccess = createAction<{
	id: string,
}>('sendFeedback_success');

export const sendFeedbackError = createAction<{
	id: string,
}>('sendFeedback_error');

//emit complaint
export const sendComplaint = createAction<IComplaint>("sendComplaint");

export const sendComplaintPending = createAction<{
	id: string;
}>("sendComplaint_pending");

export const sendComplaintSuccess = createAction<{
	id: string;
	caseId:string;
}>("sendComplaint_success");

export const sendComplaintError = createAction<{
	id: string;
}>("sendComplaint_error");
