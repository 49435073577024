import React, { useEffect, useState } from 'react';
import { Chat } from '../components/Chat/Chat';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { addUser  } from "../actions/user";
import { v4 as uuid } from 'uuid';
import Cookies from 'universal-cookie';
import { useTypedSelector } from '../reducers';
import {onTabClose} from "../components/Utils/PreventScroll"
import UAParser from 'ua-parser-js';
import { setDevice } from '../actions/session';

interface IUserHomeProps {}



export const UserHome: React.FC<IUserHomeProps> = (props) => {
	const [id, setId] = useState(uuid());
	const dispatch = useDispatch();
	const status = useTypedSelector((state) => state.user.addUserStatus);
	const parser = new UAParser();

		 useEffect(() => {
				// console.log(`id:${id}`)
				window.onbeforeunload = (e: any) => onTabClose(e);
				window.onunload = (e: any) => onTabClose(e);
				
				window.addEventListener("beforeunload", onTabClose,{passive: false} );
				window.addEventListener("unload", onTabClose, {passive: false});

				var device = parser.getDevice();
				console.log(device.type)

				if(device.type){
					dispatch(setDevice({mobile:true}))
				}else{
					dispatch(setDevice({mobile:false}))
				}
				dispatch(
					addUser({
						username: id,
					}),
				);
			}, []);

	return (

		<div>
			<Chat />
		</div> 

	);
}