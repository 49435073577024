import { createAction } from "redux-act";

export const setSocket = createAction<{
	endpoint: string
}>('setSocket');

export const setSocketPending = createAction('setSocket_pending');

export const setSocketSuccess = createAction('setSocket_success');

export const setSocketError = createAction('setSocket_error');

export const reConnect = createAction('reConnect');

export const setSocketTimeout = createAction<{
	timeout: boolean
}>('setSocketTimeout');