import { createAction } from "redux-act";

export const setSessionId = createAction<{
	id: string,
}>('setSessionId');

export const respawnSession = createAction<{
	id: string,
	userType: 'agent' | 'user' | 'guest',
}>('respawnSession');


export const setMaintenanceEnd = createAction<{
	end: string,
}>('setMaintenanceEnd');

export const setDevice = createAction<{
	mobile: boolean,
}>('setDevice');