import { IUser } from './../models/user';
import { addUser, addUserError, addUserSuccess, addUserPending } from './../actions/user';
import { Socket as SocketValue } from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { take, put, fork, call, takeLatest } from 'redux-saga/effects';
import i18n from '../i18n';

type Socket = typeof SocketValue;

// function subscribe (socket: Socket) {
// 	return eventChannel((emit) => {
// 		// event listener here...
// 		socket.on('onAddedUser', ({data, error}: {
// 			data: IUser,
// 			error: string[],
// 		}) => {
// 			emit(appendUserIntoUsersList({user: data}));
// 		});

// 		socket.on('onSwitchedChannel', ({data, error}: {
// 			data: {
// 				id: string,
// 				origin: string,
// 			},
// 			error: string[]
// 		}) => {
// 			emit(moveUserToAnotherList(data));
// 		});

// 		socket.on('onDisconnected', ({data, error}: {
// 			data: IUser,
// 			error: string[],
// 		}) => {
// 			emit(removeUserFromUserList({
// 				id: data.id,
// 			}));
// 		});

// 		return () => {}
// 	});
// }

// function* read (socket: Socket) {
// 	const channel = yield call(subscribe, socket);

// 	while (true) {
// 		const action = yield take(channel);
// 		yield put(action);
// 	}
// }

function* write (socket: Socket) {
	yield takeLatest(addUser, function* (action) {
		yield put(addUserPending());
		
		socket.emit("addUser", action.payload);

		const { data, error } = yield call(() => {
			return new Promise((res, rej) => {
				socket.on('addUserResponse', (payload: IUser) => {
					socket.off('addUserResponse');
					res(payload);
				});
			});
		});

		if (error) {
			yield put(addUserError());
			// if (i18n.language.startsWith('en')) {
			// 	window.alert('Wrong username or password has been entered, please try again.');
			// } else {
			// 	window.alert('帳戶名稱或密碼輸入錯誤, 請重新再試.');
			// }
		} else {
			yield put(addUserSuccess(data));
		}
	});

}

export function* handleUserAction(socket: Socket) {
	// yield fork(read, socket);
	yield fork(write, socket);
}