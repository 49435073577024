import { appendMessageToMessagesList, feedbackAvailable, feedbackSent} from './../actions/message';
import { IMedia } from './../models/message';
/** @format */

import { ActionStatus } from "../actions/status";
import { createReducer } from "redux-act";
import {
	getMessagesPending,
	getMessagesSuccess,
	getMessagesError,
	addMessagePending,
	addMessageSuccess,
	addMessageError,
	addCharPending,
	addCharSuccess,
	addCharError,
	emptyMessagesPending,
	emptyMessagesError,
	emptyMessagesSuccess,
	addFail,
	resetFail

} from "../actions/message";

export interface IMessageState {

	messages: {
		[roomId: string]: IMedia[],
	};

	suggestions: string[];

	failInput: string[];

	failCount: number;

	isUsingCamera: boolean;

	isUsingCall: boolean;

	feedbackSent: boolean;

	getMessagesStatus: ActionStatus;

	addMessageStatus: ActionStatus;

	addImageStatus: ActionStatus;

	addAudioStatus: ActionStatus;

	addStreamStatus: ActionStatus;

	addCharStatus: ActionStatus;

	emptyMessagesStatus: ActionStatus;
};

const initialState: IMessageState = {

	messages: {},

	suggestions: [],

	failInput: [],

	failCount: 0,

	isUsingCamera: false,

	isUsingCall: false,

	feedbackSent: true,

	getMessagesStatus: ActionStatus.INITIAL,

	addMessageStatus: ActionStatus.INITIAL,

	addImageStatus: ActionStatus.INITIAL,

	addAudioStatus: ActionStatus.INITIAL,

	addStreamStatus: ActionStatus.INITIAL,

	addCharStatus: ActionStatus.INITIAL,

	emptyMessagesStatus: ActionStatus.INITIAL,

};

export const message = createReducer<IMessageState>({}, initialState);

message.on(getMessagesPending, (state) => ({
	...state,
	getMessagesStatus: ActionStatus.PENDING,
}));

message.on(getMessagesSuccess, (state, action) => ({
	...state,
	messages: {
		...state.messages,
		[action.roomId]: action.messages,
	},
	getMessagesStatus: ActionStatus.SUCCESS,
}));

message.on(getMessagesError, (state) => ({
	...state,
	getMessagesStatus: ActionStatus.ERROR,
}));

message.on(addMessagePending, (state) => ({
	...state,
	addMessageStatus: ActionStatus.PENDING,
}));

message.on(addMessageSuccess, (state) => ({
	...state,
	addMessageStatus: ActionStatus.SUCCESS,
}));

message.on(addMessageError, (state) => ({
	...state,
	addMessageStatus: ActionStatus.ERROR,
}));

message.on(addCharPending, (state) => ({
	...state,
	addCharStatus: ActionStatus.PENDING,
}));

message.on(addCharSuccess, (state, action) => ({
	...state,
	suggestions: action.suggestions,
	addCharStatus: ActionStatus.SUCCESS,
}));

message.on(addCharError, (state) => ({
	...state,
	addCharStatus: ActionStatus.ERROR,
}));

message.on(feedbackAvailable, (state) => ({
	...state,
	feedbackSent: false,
}));

message.on(feedbackSent, (state) => ({
	...state,
	feedbackSent: true,
}));

message.on(emptyMessagesPending, (state) => ({
	...state,
	emptyMessagesStatus: ActionStatus.PENDING,
}));

message.on(emptyMessagesSuccess, (state, action) => ({
	...state,
	messages: {
		...state.messages,
		[action.roomId]: [],
	},
	feedbackSent: true,
	emptyMessagesStatus: ActionStatus.SUCCESS,
}));

message.on(emptyMessagesError, (state) => ({
	...state,
	emptyMessagesStatus: ActionStatus.ERROR,
}));




//
// ─── REDUCERS FOR SAGA USAGE ────────────────────────────────────────────────────
//
message.on(appendMessageToMessagesList, (state, action) => ({
	...state,
	messages: {
		...state.messages,
		...(
			state.messages[action.message.roomId]
			? { [action.message.roomId]: [...state.messages[action.message.roomId], action.message]}
			: { [action.message.roomId]: [action.message] }
		),
	}
}));

message.on(addFail, (state, action) => ({
	...state,
	failInput: [...state.failInput, action.input],
	failCount: state.failCount + 1
}))

message.on(resetFail, (state) => ({
	...state,
	failInput: initialState.failInput,
	failCount: initialState.failCount,
}));