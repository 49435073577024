/** @format */

import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Card, CardContent, Typography, Avatar, makeStyles, RadioGroup, FormControlLabel, Radio, TextField, ButtonGroup, Dialog, DialogContent, DialogActions, Snackbar, DialogTitle, withStyles, DialogTitleProps, IconButton, Link, CardMedia, CardActions } from "@material-ui/core";
import { IUnknown, MediaType, IMessage } from "../../models/message";
import { useTypedSelector } from "../../reducers";
import emsdIcon from "../../assets/icons/icon_EMSD1.png";
import { useDispatch } from "react-redux";
import { appendMessageToMessagesList, feedbackSent, feedbackAvailable, emptyMessages, getMessages } from "../../actions/message";
import { useTranslation } from "react-i18next";
import { onAddedClarifyingQuestion, sendFeedback } from "../../actions/bot";
import { useParams } from "react-router-dom";
// import questions from "../Chat/ChatInput/question.json";
import Fuse from "fuse.js";
import * as Yup from "yup";
import { Formik } from "formik";
import clsx from "clsx";
import emsd from "../../assets/images/EMSD.jpg";
import Cookies from 'universal-cookie';
import { ThumbUp, ThumbDown } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

interface IGreetingProps {
	// message: IMessage;
	displayIcon?: boolean;
	timeOut: boolean;
}


const useStyles = makeStyles({
	myTextBubble: {
		backgroundColor: "#ffffff",
		maxWidth: "300px",
		wordBreak: "break-word",
		paddingTop: 5,
		"&:last-child": {
			paddingBottom: 5,
		},
	},
	rightArrow: {
		display: "flex",
		"&:after": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #ffffff",
			borderRight: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	leftArrow: {
		display: "flex",
		"&:before": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #35d8f8",
			borderLeft: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	leftArrow2: {
		display: "flex",
		"&:before": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid red",
			borderLeft: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	otherTextBubble: {
		backgroundColor: "#35d8f8",
		maxWidth: "350px",
		wordBreak: "break-word",
		paddingTop: 8,
		paddingBottom: 4,
		"&:last-child": {
			paddingBottom: 8,
		},
	},
	otherTextBubble2: {
		backgroundColor: "red",
		maxWidth: "350px",
		wordBreak: "break-word",
		paddingTop: 8,
		paddingBottom: 4,
		"&:last-child": {
			paddingBottom: 8,
		},
	},
	leftCard: {
		borderRadius: "0 2px 2px 2px",
	},
	rightCard: {
		borderRadius: "2px 0 2px 2px",
	},
	card: {
		borderRadius: "2px",
	},
	avatar: {
		height: "40px",
		width: "40px",
	},
	nameAgent: {},
	text: {
		backgroundColor: "white",
		padding: "10px",
		width: "250px",
	},
	send: {
		marginTop: "5px",
		backgroundColor: "white",
		color: "black",
		textTransform: "none",
	},
	button: {
		backgroundColor: "#35d8f8",
		color: "white",
	},
	radioGroup: {
		display: "inline",
		marginLeft: "10px",
		// flexDirection: "row",
	},
	iconColor: {
		color: "white",
	},
	label: {
		// marginLeft: "auto",
		// marginRight: "auto",
	},
	a: {
		wordBreak: "break-all",
		display: "inline",
	},
	icon: {
		borderRadius: "50%",
		width: 16,
		height: 16,
		boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
		backgroundColor: "#f5f8fa",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		"$root.Mui-focusVisible &": {
			outline: "2px auto rgba(19,124,189,.6)",
			outlineOffset: 2,
		},
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5",
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)",
		},
	},
	checkedIcon: {
		backgroundColor: "#137cbd",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			width: 16,
			height: 16,
			backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
			content: '""',
		},
		"input:hover ~ &": {
			backgroundColor: "#106ba3",
		},
	},
	dialog: {
		backgroundColor: "#ffffff",
		minWidth: "300px",
	},
	dialogContent: {
		display: "flex",
		flexDirection: "column",
	},
	snack: { zIndex: 99999999 },
	media: {
		// height: 0,
		// paddingTop: "56.25%", // 16:9
		height: 160
	},
});


export const Greeting: React.FC<IGreetingProps> = (props) => {
	const classes = useStyles();
	const userId = useTypedSelector((state) => state.auth.id);
	const isRight = false;
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const { id } = useParams();
	const [isPositive, setPositive] = React.useState(true);
	const [feedbackStatus, setFeedbackStatus] = React.useState(false);
	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPositive(!isPositive);
	};
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
	
    const [disableAgent, setDisableAgent] = React.useState(false);
	
	
	const cookies = new Cookies();

	const [received, setReceived] = useState(false);


	return (
		<>
			<Grid container item spacing={2} direction={isRight ? "row-reverse" : "row"}>
				<Grid item style={{ paddingLeft: 0, marginLeft: 0, paddingRight: 0, marginRight: 0 }}>
					{props.displayIcon !== false && isRight === false ? (
						<Box
							mt={0.5}
							style={{
								textAlign: "center",
							}}>
							<Avatar classes={{ img: classes.avatar }} src={emsdIcon} />
							<Typography variant="caption" className={classes.nameAgent}>
								{t("nameVersion")}
							</Typography>
						</Box>
					) : props.displayIcon !== false ? (
						<Box mt={0.5} />
					) : (
						<Box width="0.5rem" height="2.5rem" />
					)}
				</Grid>

				<Grid item style={{ paddingRight: 0, marginRight: 0, paddingLeft: 0, marginLeft: 0 }}>
					<Grid container spacing={1} direction="column">
						{!props.timeOut && (
						<Grid item>
							<Box className={isRight && props.displayIcon ? classes.rightArrow : props.displayIcon ? classes.leftArrow : classes.card}>
								<Card
									elevation={0}
									className={isRight && props.displayIcon ? classes.rightCard : props.displayIcon ? classes.leftCard : classes.card}>
									<CardMedia className={classes.media} image={emsd} title="EMSD HQ" />
									<CardContent className={isRight ? classes.myTextBubble : classes.otherTextBubble}>
										<Typography gutterBottom style={{ wordBreak: "break-word", lineHeight: "normal", fontSize: "13px" }}>
											{t("greeting")}
										</Typography>

										<Typography gutterBottom style={{ wordBreak: "break-word", lineHeight: "normal", fontSize: "13px" }}>
											{t("greeting2")}
										</Typography>

										{/* <Typography gutterBottom style={{ wordBreak: "break-word" }}>
											<Link component="button" variant="body2" onClick={handleClickOpen}>
												{t("greetingLink")}
											</Link>
										</Typography> */}
									</CardContent>
									<CardActions
										style={{
											justifyContent: "center",
											display: "flex",
											alignItems: "center",
											backgroundColor: "#D6EAF8",
											borderTop: "1px solid rgba(0, 0, 0, .205)",
										}}>
										<Typography gutterBottom style={{ wordBreak: "break-word", fontSize: "13px" }}>

											<a
													style={{ lineHeight: "normal", fontSize: "13px" }}
													href={t("link")}
													target="_blank"
													rel="noopener noreferrer">
													{t("greetingLink")}
											</a>
											{/* <Link component="button" onClick={handleClickOpen}>
												{t("greetingLink")}
											</Link> */}
										</Typography>
									</CardActions>
									<CardActions
										style={{
											justifyContent: "center",
											display: "flex",
											alignItems: "center",
											backgroundColor: "#D6EAF8",
											borderTop: "1px solid rgba(0, 0, 0, .205)",
										}}>
										<Typography gutterBottom style={{ wordBreak: "break-word", fontSize: "13px" }}>

											<a
													style={{ lineHeight: "normal", fontSize: "13px" }}
													href={t("link2")}
													target="_blank"
													rel="noopener noreferrer">
													{t("greetingLink2")}
											</a>
										</Typography>
									</CardActions>

								</Card>
							</Box>
						</Grid>
						)}
						{props.timeOut && (
						<Grid item>
							<Box className={isRight && props.displayIcon ? classes.rightArrow : props.displayIcon ? classes.leftArrow2 : classes.card}>
								<Card
									elevation={0}
									className={isRight && props.displayIcon ? classes.rightCard : props.displayIcon ? classes.leftCard : classes.card}>
									<CardContent className={isRight ? classes.myTextBubble : classes.otherTextBubble2}>
										<Typography gutterBottom style={{ wordBreak: "break-word", lineHeight: "normal", fontSize: "13px" }}>
											{t("timeout")}
										</Typography>
									</CardContent>
								</Card>
							</Box>
						</Grid>
						)
						}

						<Grid item>
							<Dialog classes={{ paper: classes.dialog }} open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
								<DialogTitle id="customized-dialog-title">{t("disclaimerTitle")}</DialogTitle>
								<DialogContent dividers className={classes.dialogContent}>
									<Typography gutterBottom style={{ fontSize: "13px" }}>
										{t("disclaimer")}
									</Typography>
									<Typography gutterBottom style={{ fontSize: "13px" }}>
										{t("disclaimer2")}
									</Typography>
								</DialogContent>
								<DialogActions>
									<Button className={classes.send} onClick={handleClose} color="primary">
										{t(`return`)}
									</Button>
								</DialogActions>
							</Dialog>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
