/** @format */

import React, { useEffect, useState } from "react";
import {
	Grid,
	Box,
	Button,
	Card,
	CardContent,
	Typography,
	Avatar,
	makeStyles,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	ButtonGroup,
	Dialog,
	DialogContent,
	DialogActions,
	Snackbar,
	DialogTitle,
	withStyles,
	DialogTitleProps,
	IconButton,
	Link,
	CardMedia,
	CardActions,
	FormControl,
	InputLabel,
	Input,
	FormHelperText,
} from "@material-ui/core";
import { IUnknown, MediaType, IMessage } from "../../models/message";
import { useTypedSelector } from "../../reducers";
import emsdIcon from "../../assets/icons/icon_EMSD1.png";
import { useDispatch } from "react-redux";
import { appendMessageToMessagesList, feedbackSent, feedbackAvailable, emptyMessages, getMessages } from "../../actions/message";
import { useTranslation } from "react-i18next";
import { onAddedClarifyingQuestion, sendComplaint, sendFeedback } from "../../actions/bot";
import { useParams } from "react-router-dom";
// import questions from "../Chat/ChatInput/question.json";
import Fuse from "fuse.js";
import * as Yup from "yup";
import { Formik } from "formik";
import clsx from "clsx";
import emsd from "../../assets/images/EMSD.jpg";
import Cookies from "universal-cookie";
import { ThumbUp, ThumbDown } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import short, { uuid } from "short-uuid";

interface IComplaintProps {
	// message: IMessage;
	displayIcon?: boolean;
}

const useStyles = makeStyles({
	myTextBubble: {
		backgroundColor: "#ffffff",
		maxWidth: "280px",
		wordBreak: "break-word",
		paddingTop: 5,
		"&:last-child": {
			// paddingBottom: 5,
		},
	},
	rightArrow: {
		display: "flex",
		"&:after": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #ffffff",
			borderRight: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	leftArrow: {
		display: "flex",
		"&:before": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #35d8f8",
			borderLeft: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	leftArrow2: {
		display: "flex",
		"&:before": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid red",
			borderLeft: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	otherTextBubble: {
		backgroundColor: "#35d8f8",
		maxWidth: "300px",
		wordBreak: "break-word",
		paddingTop: 8,
		// paddingBottom: 4,
		"&:last-child": {
			paddingBottom: 8,
		},
	},
	otherTextBubble2: {
		backgroundColor: "red",
		maxWidth: "264px",
		wordBreak: "break-word",
		paddingTop: 8,
		// paddingBottom: 4,
		"&:last-child": {
			paddingBottom: 8,
		},
	},
	leftCard: {
		borderRadius: "0 2px 2px 2px",
	},
	rightCard: {
		borderRadius: "2px 0 2px 2px",
	},
	card: {
		borderRadius: "2px",
	},
	avatar: {
		height: "40px",
		width: "40px",
	},
	nameAgent: {},
	text: {
		backgroundColor: "white",
		padding: "10px",
		width: "250px",
	},
	send: {
		marginTop: "5px",
		backgroundColor: "white",
		color: "black",
		textTransform: "none",
	},
	button: {
		backgroundColor: "#35d8f8",
		color: "white",
	},
	radioGroup: {
		display: "inline",
		marginLeft: "10px",
		// flexDirection: "row",
	},
	iconColor: {
		color: "white",
	},
	label: {
		// marginLeft: "auto",
		// marginRight: "auto",
	},
	a: {
		wordBreak: "break-all",
		display: "inline",
	},
	icon: {
		borderRadius: "50%",
		width: 16,
		height: 16,
		boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
		backgroundColor: "#f5f8fa",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		"$root.Mui-focusVisible &": {
			outline: "2px auto rgba(19,124,189,.6)",
			outlineOffset: 2,
		},
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5",
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)",
		},
	},
	checkedIcon: {
		backgroundColor: "#137cbd",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			width: 16,
			height: 16,
			backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
			content: '""',
		},
		"input:hover ~ &": {
			backgroundColor: "#106ba3",
		},
	},
	dialog: {
		backgroundColor: "#ffffff",
		minWidth: "300px",
	},
	dialogContent: {
		display: "flex",
		flexDirection: "column",
	},
	snack: { zIndex: 99999999 },
	media: {
		// height: 0,
		// paddingTop: "56.25%", // 16:9
		height: 160,
	},
});

const emailValidate = (email: string) => {
	// const regexMatch = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
	const regexMatch =
		// /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
			email,
		);

	// console.log(regexMatch)

	if (regexMatch) return true;
	else return false;
};

const phoneValidate = (phone: string) => {
	// const regexMatch = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
	const regexMatch =
		// /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		/^(86[1]{1}[0-9]{10})$|^([1]{1}[0-9]{10})$|^([2356789]{1}[0-9]{7})$|^85[23]([2356789]{1}[0-9]{7})$|^886([0-9]{8,10})$/.test(phone);

	// console.log(regexMatch)

	if (regexMatch) return true;
	else return false;
};
export const Complaint: React.FC<IComplaintProps> = (props) => {
	const classes = useStyles();
	const userId = useTypedSelector((state) => state.auth.id);
	const inputs = useTypedSelector((state)=> state.message.failInput);
	const caseId = useTypedSelector((state) => state.bot.caseId);
	const isRight = false;
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const [complaintSent, setComplaintSent] = React.useState(false);
	// const [caseId, setCaseId] = React.useState("");
	const [cscPhone, setCscPhone] = React.useState("");
	const { id } = useParams();
	const [isPositive, setPositive] = React.useState(true);
	const [feedbackStatus, setFeedbackStatus] = React.useState(false);
	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPositive(!isPositive);
	};
	const [name, setName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [valid, setValid] = React.useState(false);
	
	const [phone, setPhone] = React.useState("");
	const [message, setMessage] = React.useState("");

	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const result = async () => {
			await axios.get(process.env.REACT_APP_IMAGE_FILE_SERVER + `/csc.json`).then((response) => {
				setCscPhone(response.data.phone);
			});
		};
		result();
	}, []);

		useEffect(() => {
			setValid((emailValidate(email)))
			console.log(emailValidate(email))
		}, [email]);

	const handleSendComplaint = () => {
		//send the complaint via saga

		const determineValid = () => {
			var send = true

			if (name && name.length > 20)
				send = false
			if (phone && !phoneValidate(phone))
				send = false
			if (email && email.length > 50)
				send = false
			if (message && message.length > 250)
				send = false
		
			return send
		};

		if (determineValid()) {
		dispatch(
			sendComplaint(
				phone
					? {
							_id: uuid(),
							name,
							email,
							phone,
							previous_question: inputs.slice(Math.max(inputs.length - 3, 0)),
							message,
							language: i18n.language,
					  }
					: {
							_id: uuid(),
							name,
							email,
							previous_question: inputs.slice(Math.max(inputs.length - 3, 0)),
							message,
							language: i18n.language,
					  },
			),
		);
		setComplaintSent(true);
		}
		//set short
		

	}
	
	return (
		<>
			<Grid container item spacing={2} style={{marginTop: "10px"}} direction={isRight ? "row-reverse" : "row"}>
				<Grid item style={{ paddingLeft: 0, marginLeft: 0, paddingRight: 0, marginRight: 0 }}>
					{props.displayIcon !== false && isRight === false ? (
						<Box
							mt={0.5}
							style={{
								textAlign: "center",
							}}>
							<Avatar classes={{ img: classes.avatar }} src={emsdIcon} />
							<Typography variant="caption" className={classes.nameAgent}>
								{t("nameVersion")}
							</Typography>
						</Box>
					) : props.displayIcon !== false ? (
						<Box mt={0.5} />
					) : (
						<Box width="0.5rem" height="2.5rem" />
					)}
				</Grid>

				<Grid item style={{ paddingRight: 0, marginRight: 0, paddingLeft: 0, marginLeft: 0 }}>
					<Grid container spacing={1} direction="column">
						<Grid item>
							<Box className={isRight && props.displayIcon ? classes.rightArrow : props.displayIcon ? classes.leftArrow : classes.card}>
								<Card
									elevation={0}
									className={isRight && props.displayIcon ? classes.rightCard : props.displayIcon ? classes.leftCard : classes.card}>
									<CardContent className={isRight ? classes.myTextBubble : classes.otherTextBubble}>
										<Typography gutterBottom style={{ wordBreak: "break-word", lineHeight: "normal", fontSize: "13px" }}>
											{/* {"抱歉, 無法回答您的問題, 如果您希望我們給您打電話跟進你的問題, 請留下您的聯繫方式, 謝謝"} */}
											{t(`complaintSorry`)}
										</Typography>

										{/* <Typography gutterBottom style={{ wordBreak: "break-word", lineHeight: "normal", fontSize: "13px" }}>
												{t("greeting2")}
											</Typography> */}

										{/* <Typography gutterBottom style={{ wordBreak: "break-word" }}>
											<Link component="button" variant="body2" onClick={handleClickOpen}>
												{t("greetingLink")}
											</Link>
										</Typography> */}
									</CardContent>
									<CardActions
										style={{
											justifyContent: "center",
											display: "flex",
											alignItems: "center",
											backgroundColor: "#D6EAF8",
											borderTop: "1px solid rgba(0, 0, 0, .205)",
										}}>
										<Typography gutterBottom style={{ wordBreak: "break-word", fontSize: "13px" }}>
											{/* <a
													style={{ lineHeight: "normal", fontSize: "13px" }}
													href={t("link")}
													target="_blank"
													rel="noopener noreferrer">
													{t("greetingLink")}
												</a> */}
											<Link component="button" onClick={handleClickOpen}>
												{/* {"按此鍵留言"} */}
												{t(`complaintOpen`)}
											</Link>
										</Typography>
									</CardActions>
									{/* <CardActions
											style={{
												justifyContent: "center",
												display: "flex",
												alignItems: "center",
												backgroundColor: "#D6EAF8",
												borderTop: "1px solid rgba(0, 0, 0, .205)",
											}}>
											<Typography gutterBottom style={{ wordBreak: "break-word", fontSize: "13px" }}>
												<a
													style={{ lineHeight: "normal", fontSize: "13px" }}
													href={t("link2")}
													target="_blank"
													rel="noopener noreferrer">
													{t("greetingLink2")}
												</a>
											</Typography>
										</CardActions> */}
								</Card>
							</Box>
						</Grid>

						<Grid item>
							<Dialog classes={{ paper: classes.dialog }} open={open} onClose={() => setOpen(false)} aria-labelledby="complaint-title-text">
								<DialogTitle id="complaint-title">
									<Box display="flex" alignItems="center">
										<Box flexGrow={1}>{t(`complaintTitle`)}</Box>
										<Box>
											<IconButton
												onClick={() => {
													handleClose();
												}}>
												<CloseIcon />
											</IconButton>
										</Box>
									</Box>
								</DialogTitle>
								{!complaintSent ? (
									<>
										<DialogContent dividers className={classes.dialogContent}>
											<FormControl>
												<InputLabel shrink={false} htmlFor="name">
													{!name ? t(`complaintName`) : ""}
												</InputLabel>

												<Input
													value={name}
													onChange={(e) => {
														setName(e.currentTarget.value);
													}}
													id="name"
													aria-describedby="name-text"
												/>
												<FormHelperText error id="name-text">
													{!name ? t(`complaintNameReq`) : name.length > 20 ? t(`complaintNameLen`) : ""}
												</FormHelperText>
											</FormControl>
											<FormControl>
												<InputLabel shrink={false} htmlFor="phone">
													{!phone ? t(`complaintPhone`) : ""}
												</InputLabel>

												<Input
													value={phone}
													onChange={(e) => {
														setPhone(e.currentTarget.value);
													}}
													id="phone"
													aria-describedby="phone-text"
												/>
												<FormHelperText error id="name-text">
													{!phone ? "" : phoneValidate(phone) ? "" : t(`complaintPhoneFormat`)}
												</FormHelperText>
												{/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
											</FormControl>
											<FormControl>
												<InputLabel shrink={false} htmlFor="email">
													{!email ? t(`complaintEmail`) : ""}
												</InputLabel>
												<Input
													value={email}
													onChange={(e) => {
														setEmail(e.currentTarget.value);
													}}
													id="email"
													aria-describedby="email-text"
												/>
												<FormHelperText error id="email-text">
													{!email ? t(`complaintEmailReq`) : (!valid || email.length > 50) ? t(`complaintEmailFormat`) : ""}
												</FormHelperText>
											</FormControl>
											<br />
											{inputs.slice(Math.max(inputs.length - 3, 0)).map((input, i) => {
												return (
													<Typography gutterBottom style={{ fontSize: "13px" }}>
														{`${t(`complaintFailedQ`)} ${i + 1}: ${input}`}
													</Typography>
												);
											})}
											<FormControl>
												<InputLabel shrink={false} htmlFor="message">
													{!message ? t(`complaintMessage`) : ""}
												</InputLabel>
												<Input
													onChange={(e) => {
														setMessage(e.currentTarget.value);
													}}
													value={message}
													id="message"
													multiline={true}
													rows={3}
													aria-describedby="message-text"
												/>
												<FormHelperText error id="message-text">
													{!message ? "" : message.length <250 ? "": t(`complaintMessageFormat`)}
												</FormHelperText>
											</FormControl>
										</DialogContent>
										<DialogActions>
											<Button
												className={classes.send}
												onClick={() => {
													if (valid && email && name) {
														handleSendComplaint();
													}
												}}
												color="primary">
												{t(`send`)}
											</Button>
										</DialogActions>
									</>
								) : (
									<>
										<DialogContent>
											<Typography gutterBottom style={{ fontSize: "13px" }}>
												{/* {"Thank you for contacting us and your message has been sent to our customer service centre for follow up."} */}
												{t(`complaintThanks`)}
											</Typography>
											<Typography gutterBottom style={{ fontSize: "13px" }}>
												{/* {`The Case ID is ${caseId}`} */}
												{`${t(`complaintCase`)} ${caseId ? caseId : t(`pleaseWait`)}`}
											</Typography>
											<Typography gutterBottom style={{ fontSize: "13px" }}>
												{/* {`You can also contact our customer service centre at ${cscPhone}`} */}
												{`${t(`complaintContactUs`)} ${cscPhone}`}
											</Typography>
										</DialogContent>
										<DialogActions>
											<Button className={classes.send} onClick={handleClose} color="primary">
												{t(`return`)}
											</Button>
										</DialogActions>
									</>
								)}
							</Dialog>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
