export enum MediaType {
	TEXT,
	IMAGE,
	AUDIO,
	CAROUSEL,
	FEEDBACK,
	CLARIFYING_QUESTION,
	COMBINED_TEXT,
	UNKNOWN,
}

export interface IMedia {

	id: string;

	userId: string;

	userType: 'agent' | 'user' | 'bot' | undefined;

	roomId: string;

	createdAt: string;

	type: MediaType;

};

export interface IMessage extends IMedia {

	content: string;

}

export interface IUnknown extends IMessage {
	feedbackId: string;

	originalInput: string;
}