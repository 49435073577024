import { respawnSession, setDevice, setMaintenanceEnd } from './../actions/session';
import { createReducer } from 'redux-act';

export interface ISessionState {

	sessionRespawnable: boolean;

	userType: 'agent' | 'user' | 'guest';

	end:string;
	mobile:boolean;

}

const initialValue: ISessionState = {
	sessionRespawnable: false,
	userType: 'guest',
	end:'',
	mobile:false,
}

export const session = createReducer<ISessionState>({}, initialValue);

session.on(respawnSession, (state, action) => ({
	...state,
	sessionRespawnable: !!(action.userType !== 'guest'),
	userType: action.userType,
}));

session.on(setMaintenanceEnd, (state, action) => ({
	...state,
	end: action.end,
}));

session.on(setDevice, (state, action) => ({
	...state,
	mobile: action.mobile,
}));


