import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSocket } from '../../actions/socket';
import { useTypedSelector } from '../../reducers';
import { ActionStatus } from '../../actions/status';
import { Loading } from '../Loading/Loading';

interface ISocketProviderProps {
}

export const SocketProvider: React.FC<ISocketProviderProps> = (props) => {

	const dispatch = useDispatch();

	const status = useTypedSelector(state => state.socket.setSocketStatus);

	// on application loaded,
	// dispatch set socket action
	useEffect(() => {
		dispatch(setSocket({
			endpoint: process.env.REACT_APP_SOCKET_IO_ENDPOINT || 'http://localhost:5000',
		}));
	}, []);

	return (
		<>
			{
				status === ActionStatus.PENDING && <Loading />
			}
			{
				status === ActionStatus.SUCCESS && props.children
			}
		</>
	)
}