/** @format */

import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../reducers";
import { useDispatch } from "react-redux";
import { getMessages } from "../../../actions/message";
import { Grid, Box }  from "@material-ui/core";
import { MediaType, IMessage, IUnknown } from "../../../models/message";
import { ClarifyingQuestion } from "../../ClarifyingQuestion/ClarifyingQuestion";
import { Feedback } from "../../Feedback/Feedback";
import { IClarifyingQuestion } from "../../../models/clarifyingQuestion";
import { IFeedback } from "../../../models/feedback";
import {ICombinedMessage} from "../../../models/combine";
import {CombinedMessage } from "../../CombinedMessage/CombinedMessage";
import { Unknown } from '../../Unknown/Unknown';
import { Greeting } from "../../Greeting/Greeting";
import { ActionStatus } from "../../../actions/status";
import { Complaint } from "../../Complaint/Complaint";

interface IChatMessagesProps {
}

export const ChatMessages: React.FC<IChatMessagesProps> = (props) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const failCount = useTypedSelector((state) => state.message.failCount);
	const messages = useTypedSelector((state) => state.message.messages[id]);
	const feedbacks = useTypedSelector((state) => state.bot.feedbackRequests);
	const clarifyingQuestions = useTypedSelector((state) => state.bot.clarifyingQuestions);
	const status = useTypedSelector((state) => state.message.getMessagesStatus);
	const socketTimeoutStatus = useTypedSelector((state) => state.socket.timeout);
		const messagesEndRef = useRef(null as any);
	useEffect(() => {
			dispatch(
				getMessages({
					roomId: id,
				}),
			);
	}, [id]);

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom()
		}, 300);
	}, [messages, feedbacks, clarifyingQuestions]);

	const scrollToBottom = () => {
		if (messagesEndRef && messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView(false, {
				inline: "nearest",
				block: "end",
				behavior: "smooth",
			})
		}
	};

	return (
		<>
			<Box p={2}>
				<Grid container spacing={1} direction="column">
					{status === ActionStatus.SUCCESS && <Greeting displayIcon={true} timeOut={false} />}
					{[
						...(messages || []),
						// ...feedbacks,
						...clarifyingQuestions,
					] &&
						[
							...(messages || []),
							// ...feedbacks,
							...clarifyingQuestions,
						]
							.sort((a, b) => parseInt(a.createdAt) - parseInt(b.createdAt))
							.reduce(
								(a, c, i, s) => [
									...a,
									c.type === MediaType.TEXT ? (
										<CombinedMessage message={c as ICombinedMessage} displayIcon={i > 0 ? s[i - 1].userId !== c.userId : true} />
									) : c.type === MediaType.CLARIFYING_QUESTION ? (
										<ClarifyingQuestion
											clarifyingQuestion={c as IClarifyingQuestion}
											displayIcon={i > 0 ? s[i - 1].userId !== c.userId : true}
										/>
									) : // ) : c.type === MediaType.FEEDBACK ? (
									// <Feedback feedback={c as IFeedback} displayIcon={i > 0 ? s[i - 1].userId !== c.userId : true} />
									c.type === MediaType.UNKNOWN ? (
										<Unknown message={c as IUnknown} displayIcon={i > 0 ? s[i - 1].userId !== c.userId : true} />
									) : (
										<></>
									),
								],
								new Array<JSX.Element | Element>(),
							)}
					{socketTimeoutStatus && <Greeting displayIcon={true} timeOut={true} />}
					{(failCount >= 3 && !socketTimeoutStatus) ? <Complaint displayIcon={true}/>:null}
				</Grid>
			</Box>
			<div
				style={{
					clear: "both",
					//minHeight:"40px" no need min height as calc messages height
				}}
				ref={messagesEndRef}></div>
		</>
	);
};
