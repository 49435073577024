import { emptyMessages } from './../actions/message';
import { ActionStatus } from './../actions/status';
import { IFeedback } from './../models/feedback';
import { IClarifyingQuestion } from './../models/clarifyingQuestion';
import { onAddedClarifyingQuestion, onAddedFeedbackRequest, sendFeedbackPending, sendFeedbackSuccess, sendFeedbackError, sendComplaintPending, sendComplaintSuccess, sendComplaintError } from './../actions/bot';
import { createReducer } from 'redux-act';

export interface IBotState {
	clarifyingQuestions: IClarifyingQuestion[];

	feedbackRequests: IFeedback[];

	sendFeedbackStatus: {
		[id: string]: ActionStatus;
	};

	sendComplaintStatus: {
		[id: string]: ActionStatus;
	};

	caseId: string;
}

const initialState: IBotState = {

	clarifyingQuestions: [],

	feedbackRequests: [],

	sendFeedbackStatus: {},

	sendComplaintStatus: {},

	caseId:''


}

export const bot = createReducer<IBotState>({}, initialState);

bot.on(onAddedClarifyingQuestion, (state, action) => ({
	...state,
	clarifyingQuestions: [
		...state.clarifyingQuestions,
		action.clarifyingQuestion
	]
}));

bot.on(onAddedFeedbackRequest, (state, action) => ({
	...state,
	feedbackRequests: [
		...state.feedbackRequests,
		action.feedback
	]
}));

bot.on(sendFeedbackPending, (state, action) => ({
	...state,
	sendFeedbackStatus: {
		...state.sendFeedbackStatus,
		[action.id]: ActionStatus.PENDING,
	}
}));

bot.on(sendFeedbackSuccess, (state, action) => ({
	...state,
	sendFeedbackStatus: {
		...state.sendFeedbackStatus,
		[action.id]: ActionStatus.SUCCESS,
	}
}));

bot.on(sendFeedbackError, (state, action) => ({
	...state,
	sendFeedbackStatus: {
		...state.sendFeedbackStatus,
		[action.id]: ActionStatus.ERROR,
	}
}));

bot.on(sendComplaintPending, (state, action) => ({
	...state,
	sendComplaintStatus: {
		...state.sendComplaintStatus,
		[action.id]: ActionStatus.PENDING,
	},
	caseId:""
}));

bot.on(sendComplaintSuccess, (state, action) => ({
	...state,
	sendComplaintStatus: {
		...state.sendComplaintStatus,
		[action.id]: ActionStatus.SUCCESS,
	},
	caseId: action.caseId
}));

bot.on(sendComplaintError, (state, action) => ({
	...state,
	sendComplaintStatus: {
		...state.sendComplaintStatus,
		[action.id]: ActionStatus.ERROR,
	},
}));

bot.on(emptyMessages, (state) => ({
	...state,
	carousels: [],
	clarifyingQuestions: [],
	feedbackRequests: [],
	sendFeedbackStatus: {},
}));