/** @format */

import React, { useState, useEffect } from "react";
import { Grid, Box, Card, CardContent, Typography, Avatar, makeStyles, CardMedia, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import { ICombinedMessage } from "../../models/combine";
import { useTypedSelector } from "../../reducers";

import icon_EMSD1 from "../../assets/icons/icon_EMSD1.png";
//import Zoom from "react-medium-image-zoom";
import { useTranslation } from "react-i18next";
import { Feedback } from "../Feedback/Feedback";
import { IFeedback } from "../../models/feedback";
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	FacebookIcon,
	LinkedinIcon,
	TwitterIcon,
	WhatsappIcon,
} from "react-share";
import LinkIcon from "@material-ui/icons/Link";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import axios from 'axios';
import { useLocation } from "react-router";

interface ICombinedMessageProps {
	message: ICombinedMessage;
	displayIcon?: boolean;
}

const useStyles = makeStyles({
	myTextBubble: {
		backgroundColor: "#ffffff",
		maxWidth: "300px",
		wordBreak: "break-word",
		paddingTop: 5,
		"&:last-child": {
			paddingBottom: 5,
		},
	},
	rightArrow: {
		display: "flex",
		"&:after": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #ffffff",
			borderRight: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	leftArrow: {
		display: "flex",
		"&:before": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #35d8f8",
			borderLeft: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	otherTextBubble: {
		backgroundColor: "#35d8f8",
		maxWidth: "300px",
		wordBreak: "break-word",
		paddingTop: 8,
		"&:last-child": {
			paddingBottom: 8,
		},
	},
	leftCard: {
		borderRadius: "0 2px 2px 2px",
	},
	rightCard: {
		borderRadius: "2px 0 2px 2px",
	},
	card: {
		borderRadius: "2px",
	},
	avatar: {
		height: "40px",
		width: "40px",
	},
	nameAgent: {},
	a: {
		wordBreak: "break-all",
		display: "inline"
	},
});

const SimpleDialog = (props:any) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { onClose, url, open, question, answer } = props;
			const [copied, setCopied] = useState(false);
			//const str = `市民可於機電工程署網頁下載<url="https://www.emsd.gov.hk/filemanager/en/content_261/LPG_Distributors_List.pdf">"獲香港註冊氣體供應公司批准的瓶裝石油氣分銷商名冊"</url>，查詢分銷<pic>hihi</pic>商是否<pic>yoyo</pic>獲批准的分銷商`;

			useEffect(() => {
				if (copied === true) {
					setTimeout(() => {
						setCopied(false);
					}, 3000);
				}
			}, [copied]);

	const handleClose = () => {
		onClose();
	};

	  const handleClipboard = () => {
			if (navigator.clipboard && navigator.clipboard.writeText(url)) {navigator.clipboard.writeText(url);
				setCopied(true)
			}
			else {(window as any).clipboardData.setData("Text", url);
				setCopied(true)
			}
		};
	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title" style={{ textAlign: "justify" }}>
				<div style={{ display: "inline-block", width: "50%", textAlign: "left" }}>{t(`shareTitle`)}</div>
				<div style={{ display: "inline-block", width: "50%" }}>
					{copied && <Typography style={{ textAlign: "right", marginRight: "6px", fontSize: 10 }}>{t(`shareCopy2`)}</Typography>}
				</div>
			</DialogTitle>

			<DialogContent>
				<FacebookShareButton url={url}>
					<IconButton style={{ width: "64px", height: "64px", borderRadius: 0, padding: 0 }}>
						<FacebookIcon />
					</IconButton>
				</FacebookShareButton>
				<WhatsappShareButton url={url}>
					<IconButton style={{ width: "64px", height: "64px", borderRadius: 0, padding: 0 }}>
						<WhatsappIcon />
					</IconButton>
				</WhatsappShareButton>
				<LinkedinShareButton url={url}>
					<IconButton style={{ width: "64px", height: "64px", borderRadius: 0, padding: 0 }}>
						<LinkedinIcon />
					</IconButton>
				</LinkedinShareButton>
				<TwitterShareButton url={url}>
					<IconButton style={{ width: "64px", height: "64px", borderRadius: 0, padding: 0 }}>
						<TwitterIcon />
					</IconButton>
				</TwitterShareButton>
				<Tooltip title={t(`shareEmail`) as any}>
					<a
						href={`mailto:?subject=${question}&body=${t(`shareEmailIntro`)}%0D%0A${answer.replaceAll(/<(.|\n)*?>/g, "")}%0D%0A%0D%0A${t(`shareEmailDetail`)}%0D%0A${url}`}
						target="_blank"
						rel="noopener noreferrer">
						<IconButton style={{ width: "64px", height: "64px", backgroundColor: "grey", color: "white", borderRadius: 0, marginLeft: "5px" }}>
							<MailOutlineIcon style={{ fontSize: "40px" }} />
						</IconButton>
					</a>
				</Tooltip>
				<Tooltip title={t(`shareCopy`) as any}>
					<IconButton
						style={{ width: "64px", height: "64px", backgroundColor: "grey", color: "white", borderRadius: 0, marginLeft: "5px" }}
						onClick={() => {
							handleClipboard();
						}}>
						<LinkIcon style={{ fontSize: "40px" }} />
					</IconButton>
				</Tooltip>
			</DialogContent>
		</Dialog>
	);
};


export const CombinedMessage: React.FC<ICombinedMessageProps> = (props) => {
	const classes = useStyles();
	const userId = useTypedSelector((state) => state.auth.id);
	const location = useLocation();
	const {t} = useTranslation();
	const {i18n} = useTranslation();
	const [filteredContent, setFilteredContent] = useState([] as string[]); 
	const isRight = !props.message.userId || props.message.userId === userId;
	const feedbacks = useTypedSelector((state) => state.bot.feedbackRequests);
	const [reply, setReply] = useState();
	const device = useTypedSelector((state) => state.session.mobile);
		const [show, setShow] = useState(false);
		const [url, setUrl] = useState("");
		const [question, setQuestion] = useState("");
		const [answer, setAnswer] = useState("");


	const handleFilterContent = (content: string) => {
			const regex = /(?<url><url="[^\s]*">[^>]+?<\/url>)|(?<pic><pic>.+?<\/pic>)|(?<linebreak><br(\/)?>)|[^\<\n]+/gm;

			const matches = content.toString().matchAll(regex);

			const orderedMatches = [] as string[];

			for (let match of matches as any) {
				orderedMatches.push(match[0]);
			}
			setFilteredContent(orderedMatches);
	}

	useEffect(()=> {
		console.log(i18n.language)	
	}, [i18n])

		useEffect(() => {

			Object.values(feedbacks).map((feedback, index) => {
				if (props.message.id == feedback.replyId) setReply(Object.values(feedbacks)[index] as any);
			});
		}, [feedbacks]);

		useEffect(()=> {
			console.log(location);
			console.log(reply);
		}, [reply])

			const share = async () => {
				
				if (reply) {
					let longUrl = `${process.env.REACT_APP_DOMAIN}/link/qa?title=${props.message.content
						.replace(/&/g, "XQMX")
						.replace(/\?/g, "%3F")
						.replace(/\//g, "%2F")
						.replace(/=/g, "%3D")}&description=${(reply as any).content
						.replace(/&/g, "XQMX")
						.replace(/\?/g, "%3F")
						.replace(/\//g, "%2F")
						.replace(/=/g, "%3D")}&question=${(reply as any).questionShare
						.replace(/&/g, "XQMX")
						.replace(/\?/g, "%3F")
						.replace(/\//g, "%2F")
						.replace(/=/g, "%3D")}&ln=${i18n.language.includes("en") ? "en" : i18n.language.includes("CN") ? "sc" : "tc"}&date=${
						props.message.createdAt
					}&replyDate=${(reply as any).createdAt}`;

					setQuestion(props.message.content)
					setAnswer((reply as any).content)

					const result = await axios.post(
						`${process.env.REACT_APP_DOMAIN}/share/shortUrls`,
						{ fullUrl: longUrl },
						{ "Content-Type": "application/json" } as any,
					);
					console.log(result);
				console.log(longUrl)

					const shareData = {
						title: question,
						
						// url: `${process.env.REACT_APP_DOMAIN}/share/${result.data}`,
						url: `${process.env.REACT_APP_DOMAIN}/share/${result.data}`,
					};

					// if (navigator.share()) {
					if (device) {
						try {
							navigator
								.share(shareData)
								.then(() => console.log("Share was successful."))
								.catch((error) => console.log("Sharing failed", error));
						} catch {
							console.log(`Your system doesn't support sharing files.`);
						}
					} else {
						console.log(result.data);
						setUrl(`${process.env.REACT_APP_DOMAIN}/share/${result.data}`);
						setShow(true);
					}	
					// }
				}
			};

	useEffect(()=> {
		//console.log(props.message.content)
		handleFilterContent(props.message.content) 
	}, [])

	const handleClose = () => {
		setShow(false);
	};
	return (
		<>
			<Grid container item spacing={2} direction={isRight ? "row-reverse" : "row"}>
				<Grid item style={{ paddingLeft: 0, marginLeft: 0, paddingRight: 0, marginRight: 0 }}>
					{props.displayIcon !== false && isRight === false ? (
						<Box
							mt={0.5}
							style={{
								textAlign: "center",
							}}>
							<Avatar classes={{ img: classes.avatar }} src={icon_EMSD1} />
							<Typography variant="caption" className={classes.nameAgent}>
								{t("nameVersion")}
							</Typography>
						</Box>
					) : props.displayIcon !== false ? (
						<Box mt={0.5} />
					) : (
						<Box width="0.5rem" height="2.5rem" />
					)}
				</Grid>

				<Grid item style={{ paddingRight: 0, marginRight: 0, paddingLeft: 0, marginLeft: 0 }}>
					<Box className={isRight && props.displayIcon ? classes.rightArrow : props.displayIcon ? classes.leftArrow : classes.card}>
						<Card elevation={0} className={isRight && props.displayIcon ? classes.rightCard : props.displayIcon ? classes.leftCard : classes.card}>
							<CardContent className={isRight ? classes.myTextBubble : classes.otherTextBubble}>
								{filteredContent.map((contentPart: string) => {
									if (contentPart.includes("<br>" || "<br/>")) {
										return <br></br>;
									} else if (contentPart.includes("<url=")) {
										// substring last bit, and first bit  put last bit as a href linking to first bit
										const urlRegex = /<url="([^\s]*)">([^>]+?)<\/url>/gm;
										const matches = contentPart
											.toString()
											.replace(/(\r\n|\n|\r)/gm, "")
											.matchAll(urlRegex);
										const array = Array.from(matches);
										//console.log(array[0]);
										return (
											<a
												style={{ lineHeight: "normal", fontSize: "13px" }}
												className={classes.a}
												href={array[0][1]}
												// style={{ display: "table-cell", fontSize: "13px" }}
												target="_blank"
												rel="noopener noreferrer">
												{array[0][2]}
											</a>
										);
									} else if (contentPart.includes("<pic>")) {
										//substring first bit and last
										const imageServer = process.env.REACT_APP_IMAGE_FILE_SERVER;
										const image = `${imageServer}/${contentPart.substr(5).slice(0, -6)}.jpg`;
										//console.log(image);
										return (
											<a href={image} 
											// style={{ display: "table-cell" }}
											target="_blank" rel="noopener noreferrer">
												<CardMedia style={{ height: `300px`, width: `300px` }} image={image} />
											</a>
										);
									} else
										return <Typography style={{ display: "inline", wordBreak: "break-word", fontSize: "13px" }}>{contentPart}</Typography>;
								})}
							</CardContent>
						</Card>
					</Box>
				</Grid>
			</Grid>
			{!device && <SimpleDialog url={url} open={show} onClose={handleClose} question={question} answer={answer} />}
			{reply && <Feedback feedback={reply as unknown as IFeedback} displayIcon={true} share={share} />}
		</>
	);
};
