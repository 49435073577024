import { IUser } from './../models/user';
import { createAction } from "redux-act";

export const addUser = createAction<{
	username: string,
}>('addUser');

export const addUserPending = createAction('addUser_pending');

export const addUserSuccess = createAction<IUser>('addUser_success');

export const addUserError = createAction('addUser_error');