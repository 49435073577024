/** @format */

import React, { useEffect } from "react";
import { makeStyles, Dialog, CircularProgress } from "@material-ui/core";

interface ILoadingProps {}

const useStyles = makeStyles({});
export const Loading: React.FC<ILoadingProps> = (props) => {
	const classes = useStyles();

	useEffect(() => {}, []);

	//list of navlinks to the various pages
	return (
		<Dialog
			open={true}
			PaperProps={{
				style: {
					backgroundColor: "transparent",
					boxShadow: "none",
					overflow:"hidden",
				},
			}}>
			<CircularProgress  />
		</Dialog>
	);
};
