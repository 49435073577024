import { respawnSession, setSessionId } from './../actions/session';
import { reConnect, setSocket, setSocketError, setSocketPending, setSocketSuccess, setSocketTimeout } from "./../actions/socket";
import { fork, call, take, put, takeLatest } from "redux-saga/effects";
import io from "socket.io-client";
import { handleUserAction } from "./user";
import { handleMessageAction } from "./message";
import Cookies from "js-cookie";
import { eventChannel } from "redux-saga";
import { Socket as SocketValue } from 'socket.io-client';
import { handleBotAction } from './bot';

type Socket = typeof SocketValue;

function subscribe (socket: Socket) {
	return eventChannel((emit) => {

		// remove old cookie 
		socket.on("timeout", () => {
			Cookies.remove("sessionId");
			// window.location.reload();
			emit(setSocketTimeout({timeout:true}));
		});

		// respawn session
		socket.on('respawnSession', ({type}: {type: 'agent' | 'user' | 'guest'}) => {
		 	emit(respawnSession({
				id: Cookies.get("sessionId") || "",
				userType: type,
			}));
		});

		// set session if server distribute client a new session id
		socket.on('setSessionId', ({sessionId}: {sessionId: string}) => {
			Cookies.set("sessionId", sessionId);
			emit(setSessionId({
				id: sessionId,
			}));
		});

		// on connected
		socket.on("connect", () => {
			emit(setSocketSuccess());
		});

		socket.on('disconnect', () => {
			window.location.reload();
			emit(setSocketTimeout({timeout:true}));
		});

		return () => {}
	});
}

function* read (socket: Socket) {
	const channel = yield call(subscribe, socket);

	while (true) {
		const action = yield take(channel);
		yield put(action);
	}
}

function* write (endpoint: string) {

	yield takeLatest(reConnect, function* (action) {

		const sessionId = Cookies.get('sessionId');
		
		yield put(setSocketPending());

		try {
		const socket = io(endpoint, {
			...(sessionId && {
				query: {
					sessionId,
				}
			}),
		});
		console.log(socket)
		yield put(setSocketSuccess())
		return socket

		}
		catch(e) {
			yield put(setSocketError())
		}
		
	});
}

function connect(endpoint: string) {
	// get session id by cookie
	const sessionId = Cookies.get('sessionId');

	// attach session id retrieved from cookie if not undefined
	const socket = io(endpoint, {
		...(sessionId && {
			query: {
				sessionId,
			}
		}),
	});

	return socket;
}

function* flow() {
	// set socket status
	yield put(setSocketPending());

	// connect to socket io server
	const { payload } = yield take(setSocket);

	const socket = yield call(connect, payload.endpoint);
	
	const reSocket = yield fork(write, payload.endpoint);
	// session id
	yield fork(read, socket);


	// invoke all middleware to take all of the action
	yield fork(handleMessageAction, socket);
	yield fork(handleUserAction, socket);
	yield fork(handleBotAction, socket);
}

export default function* rootSaga() {
	yield fork(flow);
}
