import { ActionStatus } from './../actions/status';
import { createReducer } from 'redux-act';
import { setSocketPending, setSocketSuccess, setSocketError, setSocketTimeout } from '../actions/socket';

export interface ISocketState {

	setSocketStatus: ActionStatus;
	
	timeout: boolean;

}

const initialState: ISocketState = {

	setSocketStatus: ActionStatus.INITIAL,
	timeout: false

};

export const socket = createReducer<ISocketState>({}, initialState);

socket.on(setSocketPending, (state) => ({
	...state,
	setSocketStatus: ActionStatus.PENDING,
}));

socket.on(setSocketSuccess, (state) => ({
	...state,
	setSocketStatus: ActionStatus.SUCCESS,
}));

socket.on(setSocketError, (state) => ({
	...state,
	setSocketStatus: ActionStatus.ERROR,
}));

socket.on(setSocketTimeout, (state, action) => ({
	...state,
	timeout: action.timeout,
}));