import { IMedia } from './../models/message';
import { createAction } from "redux-act";

export const getMessages = createAction<{
	roomId: string,
}>('getMessages');

export const getMessagesPending = createAction('getMessages_pending');

export const getMessagesSuccess = createAction<{
	roomId: string,
	messages: IMedia[],
}>('getMessages_success');

export const getMessagesError = createAction('getMessages_error');

export const addMessage = createAction<{
	content: string,
	device:string,
	roomId: string,
	isInputHint: boolean,
	isSelected: boolean,
}>('addMessage');

export const addMessagePending = createAction('addMessage_pending');

export const addMessageSuccess = createAction('addMessage_success');

export const addMessageError = createAction('addMessage_error');

export const addChar = createAction<{
	content: string,
}>('addChar');

export const addCharPending = createAction('addChar_pending');

export const addCharSuccess = createAction<{
	suggestions: any[]
}>('addChar_success');

export const addCharError = createAction('addChar_error');

export const feedbackAvailable = createAction("feedbackAvailable");

export const feedbackSent = createAction("feedbackSent");

export const emptyMessages = createAction<{
	roomId: string,
}>("emptyMessages");

export const emptyMessagesPending = createAction('emptyMessages_pending');

export const emptyMessagesSuccess = createAction<{
	roomId: string,
}>('emptyMessages_success');

export const emptyMessagesError = createAction('emptyMessages_error');

//
// ─── ACTIONS FOR SAGA USAGE ─────────────────────────────────────────────────────
//
export const appendMessageToMessagesList = createAction<{
	message: IMedia,
}>('appendMessageToMessagesList');

export const addFail = createAction<{
	input: string;
}>("addFail");

export const resetFail = createAction("resetFail");


