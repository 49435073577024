import React, { useState, useEffect } from 'react';
import { Grid, Box, Avatar, Card, CardContent, Typography, makeStyles, Button, TextField, CardActions } from '@material-ui/core';
import { IClarifyingQuestion } from '../../models/clarifyingQuestion';
import { useTypedSelector } from '../../reducers';
import { addMessage, feedbackSent, feedbackAvailable } from '../../actions/message';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendFeedback } from "../../actions/bot";
import icon_EMSD1 from "../../assets/icons/icon_EMSD1.png";
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from "formik";

interface IClarifyingQuestionProps {

	clarifyingQuestion: IClarifyingQuestion;

	displayIcon?: boolean;

}

const useStyles = makeStyles({
	myTextBubble: {
		backgroundColor: "#ffffff",
		// maxWidth: "300px",
		wordBreak: "break-word",
		paddingTop: 5,
		"&:last-child": {
			paddingBottom: 5,
		},
	},
	rightArrow: {
		display: "flex",
		"&:after": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #ffffff",
			borderRight: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	leftArrow: {
		display: "flex",
		"&:before": {
			content: '""',
			width: 0,
			height: 0,
			borderTop: "13px solid #35d8f8",
			borderLeft: "13px solid transparent",
			margin: 0,
		},
		marginTop: 0,
		margin: 0,
	},
	otherTextBubble: {
		backgroundColor: "#35d8f8",
		// maxWidth: "300px",
		wordBreak: "break-word",
		paddingTop: 8,
		"&:last-child": {
			paddingBottom: 8,
		},
	},
	leftCard: {
		borderRadius: "0 2px 2px 2px",
		backgroundColor: "transparent",
	},
	rightCard: {
		borderRadius: "2px 0 2px 2px",
		backgroundColor: "transparent",
	},
	card: {
		borderRadius: "2px 2px 2px 2px",
		backgroundColor: "transparent",
	},
	avatar: {
		height: "40px",
		width: "40px",
	},
	nameAgent: {},
	a: {
		wordBreak: "break-all",
		display: "inline",
	},
	text: {
		backgroundColor: "white",
		padding: "10px",
		width: "250px",
	},
	send: {
		marginTop: "5px",
		backgroundColor: "#0089ea",
		color: "black",
		textTransform: "none",
	},
});

export const ClarifyingQuestion: React.FC<IClarifyingQuestionProps> = (props) => {
	const classes = useStyles();

	const userId = useTypedSelector(state => state.auth.id);
	const [furtherClicked, setFurtherClicked] = useState(false);
	const isRight = (!props.clarifyingQuestion.userId || props.clarifyingQuestion.userId === userId);
	const [feedbackStatus, setFeedbackStatus] = useState(false);
	const { id } = useParams();
	const device = useTypedSelector((state) => state.session.mobile);
	const [CQIncorrect, setCQIncorrect] = useState(false);
	const dispatch = useDispatch();
	const {t} = useTranslation();

	return (
		<Grid container item spacing={2} direction={isRight ? "row-reverse" : "row"}>
			<Grid item style={{ paddingLeft: 0, marginLeft: 0, paddingRight: 0, marginRight: 0 }}>
				{props.displayIcon !== false && isRight === false ? (
					<Box
						mt={0.5}
						style={{
							textAlign: "center",
						}}>
						<Avatar classes={{ img: classes.avatar }} src={icon_EMSD1} />
						<Typography variant="caption" className={classes.nameAgent}>
							{t("nameVersion")}
						</Typography>
					</Box>
				) : props.displayIcon !== false ? (
					<Box mt={0.5} />
				) : (
					<Box width="0.5rem" height="2.5rem" />
				)}
			</Grid>

			<Grid item style={{ paddingRight: 0, marginRight: 0, paddingLeft: 0, marginLeft: 0 }}>
				<Box className={isRight && props.displayIcon ? classes.rightArrow : props.displayIcon ? classes.leftArrow : classes.card}>
					<Card elevation={0} className={isRight && props.displayIcon ? classes.rightCard : props.displayIcon ? classes.leftCard : classes.card}>
						<CardContent className={isRight ? classes.myTextBubble : classes.otherTextBubble}>
							<Typography style={{ fontSize: "13px" }}>{t(`clarify`)}</Typography>
						</CardContent>

						{!furtherClicked &&
							props.clarifyingQuestion.answers.map((answer, i) => (
								<CardActions style={{ backgroundColor: "#D6EAF8", borderTop: "1px solid rgba(0, 0, 0, .205)" }}>
									<Button
										size="small"
										style={{
											textTransform: "none",
											whiteSpace: "nowrap",
										}}
										// variant="contained"
										color="primary"
										disableElevation
										onClick={(e) => {
											setFurtherClicked(true);
											dispatch(
												addMessage({
													roomId: id,
													content: answer,
													device: device?'mobile':'desktop',
													isInputHint: true,
													isSelected: true,
												}),
											);
										}}>
										{answer}
									</Button>
								</CardActions>
							))}
					</Card>
				</Box>
			</Grid>
		</Grid>
	);
}