/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import { addUser  } from "../actions/user";
// import { Chat } from '../components/Chat/Chat';
import { v4 as uuid } from "uuid";
import {onTabClose} from "../components/Utils/PreventScroll";
import { useTypedSelector } from "../reducers";
// import { CharacterScreen } from '../components/CharacterScreen/CharacterScreen';
// import { setDevice } from '../actions/session';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";
import Box from "@material-ui/core/Box/Box";

import Button from "@material-ui/core/Button/Button";

import { useLocation, Redirect } from "react-router-dom";
import { CombinedMessage } from "../components/CombinedMessage/CombinedMessage";
import { MediaType } from "../models/message";
import axios from "axios";
import { Loading } from "../components/Loading/Loading";
import { Card, CardActions, CardContent, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UAParser from "ua-parser-js";


const useStyles = makeStyles((theme) => ({
	userMessages: {
		display: "flex",
		width: "100vw"|| "fill-available" || "-moz-available" || "-webkit-fill-available" || "100%",
		height: "100vh"|| "fill-available" || "-moz-available" || "-webkit-fill-available"  || "100%",
		margin: "auto",
		justifyContent: "center",
		alignItems: "center",
		// backgroundColor: "black",
		backgroundColor:"grey",
		// overflowX: "hidden",
		// // marginTop: "25px",
		// marginBottom: "41px",
		// flexGrow: 1,
		// height: "calc(100vh - 41px)",
	},
}));
interface ILinkHomeProps {

}

export const LinkHome:React.FC<ILinkHomeProps> = (props) => {
	const [id, setId] = useState(uuid());
	const dispatch = useDispatch();
	const {t} = useTranslation();
	// const screen =  useTypedSelector((state) => state.shared.screen)
	const classes = useStyles();
	const parser = new UAParser();
	const location = useLocation();
	const [more, setMore] = React.useState(false);
	const [TITLE, setTitle] = React.useState("");
	const [DESCRIPTION, setDescription] = React.useState("");
	const [IMAGE_URL, setImageUrl] = React.useState("");
	const [URL, setUrl] = React.useState("");
	const [OBJ, setObj] = React.useState({});
	const [REPLY, setReply] = React.useState({});
	const [verify, setVerify] = React.useState(undefined);
	const [display, setDisplay] = React.useState(false);
	const [expiry, setExpiry] = React.useState("within a month");
	const [language, setLanguage] = React.useState("zh-HK");
	const messagesEndRef = useRef(null as any);
	const { i18n } = useTranslation();

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [language]);

	useEffect(() => {
		// console.log(`id:${id}`)
		window.onbeforeunload = (e: any) => onTabClose(e);
		window.onunload = (e: any) => onTabClose(e);

		window.addEventListener("beforeunload", onTabClose, { passive: false });
		window.addEventListener("unload", onTabClose, { passive: false });
	}, []);

		useEffect(() => {
			setTimeout(() => {
				scrollToBottom();
				// console.log(messagesEndRef.current)
				scrollElementIntoView(messagesEndRef.current, 'instant')
			}, 600);
		}, []);

		const scrollToBottom = () => {
			if (messagesEndRef && messagesEndRef.current) {
				messagesEndRef.current.scrollIntoView(false, {
					inline: "nearest",
					block: "end",
					behavior: "smooth",
				});
			}
		};

		const scrollElementIntoView = (element: HTMLElement, behavior?: "smooth" | "instant" | "auto") => {
			let scrollTop = window.pageYOffset || element.scrollTop;

			// Furthermore, if you have for example a header outside the iframe
			// you need to factor in its dimensions when calculating the position to scroll to
			// const headerOutsideIframe = window.parent.document.getElementsByClassName("myHeader")[0].clientHeight;

			const finalOffset = element.getBoundingClientRect().top + scrollTop 
			// + headerOutsideIframe;

			window.parent.scrollTo({
				top: finalOffset,
				behavior: behavior as any || "auto",
			});
		};



	useEffect(() => {

		if (location.pathname.split("/")[2] == "qa") {
			if (Object.fromEntries(new URLSearchParams(location.search))) {
				var linkDetails = Object.fromEntries(new URLSearchParams(location.search));
				if (linkDetails.ln) {
				var url = linkDetails.ln
				url.includes("en") ? setLanguage("en-HK") : url.includes("sc") ? setLanguage("zh-CN") : setLanguage("zh-HK");
				}

				setDescription(linkDetails.description.replace(/XQMX/g, "&"));
				setTitle(linkDetails.title.replace(/XQMX/g, "&"));
				setObj({
					userId: "",
					content: linkDetails.title.replace(/XQMX/g, "&"),
					createdAt: linkDetails.date,
					userType: "user",
					roomId: id,
					type: MediaType.TEXT,
				});
				setReply({
					userId: "bot",
					content: linkDetails.description.replace(/XQMX/g, "&"),
					createdAt: linkDetails.replyDate,
					userType: "bot",
					roomId: id,
					type: MediaType.TEXT,
				});
				// 			id(pin):"5551ffde-d323-4ed3-88f1-fb446088f222"
				// userId(pin):"RgzQM6VgV-YmPsLupOVeYXbw5jgbMD5l"
				// userType(pin):"user"
				// roomId(pin):"RgzQM6VgV-YmPsLupOVeYXbw5jgbMD5l"
				// content(pin):"hi"
				// createdAt(pin):"1616471161930"
				// type(pin):"text"
			}
		}

		// console.log(location);


		const getVerify = async () => {

			var x = await axios.get(
				`${process.env.REACT_APP_DOMAIN}/share/verify/${location.search
					.substring(1)
					.replace(/&/g, "%26")
					.replace(/\?/g, "%3F")
					.replace(/\//g, "%2F")
					.replace(/=/g, "%3D")}`,
			);
			console.log(x.data.verified)
		setExpiry(x.data.expiry? new Date(x.data.expiry).toLocaleDateString("zh-HK"):"within a month")
		setVerify(x.data.verified)
		}

		var ua = parser.getUA()
		if (!(ua.includes("Prerender") || ua.includes("prerender"))) getVerify();

	}, []);

	useEffect(()=>{
		// console.log(verify.verify)

		if (verify) {
			console.log(`hi should trigger`)
			setDisplay(true)
		}
	}, [verify])

	return (
		<>
			{
				more  ? (
					<Redirect to={`/${language.includes("en") ? "en" : language.includes("CN") ? "sc" : "tc"}`} />
				) :
				display ? (
					// <>
					<Box className={classes.userMessages}>
						{/* {IMAGE_URL && <CarouselItem isLink={"yes"} title={TITLE} description={DESCRIPTION} imageUrl={IMAGE_URL} detailUrl={URL} />} */}

						<Box p={2} boxShadow={3} style={{ width: "60vw", backgroundColor: "#eaeaea", borderRadius: "4px" }}>
							<Grid container spacing={1} direction="column">
								<Grid item>
									{Object.keys(OBJ).length > 0 && (
										<CombinedMessage
											// isRight={"yes"}
											message={OBJ as any}
											// outer={true}
										/>
									)}
								</Grid>
								<Grid item>
									{Object.keys(REPLY).length > 0 && (
										<CombinedMessage
											// isRight={"no"}
											displayIcon={true}
											message={REPLY as any}
											//  outer={true}
										/>
									)}
								</Grid>
							</Grid>
						</Box>

						<Card style={{ position: "fixed", bottom: "20px", margin: "auto", maxWidth: "60vw" }}>
							<CardContent>
								<Typography variant="body2" style={{ wordBreak: "break-word" }}>
									{/* Disclaimer: This hyperlink and related information was generated and shared via EMSD's Witty Bear Chatbot, and set to expire {expiry}. Please click the button
										bottom right to ask us more questions! */}
									{t(`shareDisclaimer`) + " " + expiry}
								</Typography>
							</CardContent>
							{/* <CardActions></CardActions> */}
						</Card>
						<Button
							onClick={() => {
								setMore(true);
							}}
							style={{ position: "fixed", right: "10px", bottom: "20px" }}
							size="small"
							color="primary"
							variant="contained">
							{t(`shareQuestion`)}
						</Button>
						<div
							style={{
								clear: "both"
							}}
							ref={messagesEndRef}></div>
					</Box>
				) : (
					// 	</>
					<Loading />
				)
			}
		</>
	);
};
