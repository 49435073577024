import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import emsdIcon from "../assets/icons/bear.png";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setMaintenanceEnd } from '../actions/session';
import { useTypedSelector } from '../reducers';

interface IMaintenanceProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems:"center",
      justifyContent:"center",
      height:"100%",
      borderRadius:"0px"
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
        marginLeft: "auto",
        marginRight: "auto",
      width: "150px",
      height: "150px"
    }, 
  }),
);

export const Maintenance: React.FC<IMaintenanceProps> = (props) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState("zh-HK");
    const location = useLocation();
    const end = useTypedSelector((state) => state.session.end);
    const [endTime, setEndTime] = useState("");
    const [chineseAmpm, setChineseAmpm] = useState("");
    const [englishAmpm, setEnglishAmpm] = useState("");
    const [chineseDate, setChineseDate] = useState("");
    const [englishDate, setEnglishDate] = useState("");

    const addEightForHKTDisplay = (date:any) => {
			var isoDate = new Date(date)
			isoDate.setHours(isoDate.getHours() + 8)
			return isoDate.toISOString()
    }

    function getFormattedDate(input: any) {
    var pattern = /(.*?)\/(.*?)\/(.*?)$/;
    var result = input.replace(pattern,function(match: any,p1:any,p2:any,p3:any){
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        return (p1<10?"0"+p1:p1) + " " + months[(p2-1)] + " " + p3;
    });

    return result;
    }

    
    useEffect(() => {
		var url = location.pathname.split('/')[1] && location.pathname.split('/')[1].length === 2 ? location.pathname.split('/')[1] : document.referrer;
		url.includes("en")  ? setLanguage("en-HK") : url.includes("sc") ? setLanguage("zh-CN") : setLanguage("zh-HK")
		// console.log(id)
	}, []);

	useEffect(() => {
		i18n.changeLanguage(language);
  }, [language]);
  
  useEffect(() => {
    if (end) {
      // console.log(end)
    var endingDateTime = addEightForHKTDisplay(end).slice(0,16)
    console.log(endingDateTime)
    setEndTime(endingDateTime.substr(-5))
    console.log(endingDateTime.slice(11,13))
    if (parseInt(endingDateTime.slice(11,13)) >= 12){
      setChineseAmpm("晚上");
      setEnglishAmpm("pm");
    }
    else{
      setChineseAmpm("早上");
      setEnglishAmpm("am");
    }
    setEnglishDate(getFormattedDate(new Date(endingDateTime.slice(0,10)).toLocaleDateString('en-HK')))
    var shortDate = new Date(endingDateTime.slice(0,10)).toLocaleDateString('en-HK')
    var arr = shortDate.split("/")
    setChineseDate(arr[2]+"年"+arr[1]+"月"+arr[0]+"日")

    
    }
    
	}, [end]);

	return (

        <Paper elevation={0}
         style={{width:"100vw", height:"100vh"}}
        > 
                <Card className={classes.root}>
                <div className={classes.details}>
                <CardMedia
        className={classes.cover}
        image={emsdIcon}
        title={t(`nameVersion`)}
      />
      

        <CardContent className={classes.content}>
        
          <Typography component="h5" style={{marginBottom:"10px"}} variant="h5">
            {t(`maint`)}
          </Typography>
          {/* <Typography variant="subtitle1" style={{lineHeight: "normal", marginBottom:"10px"}} color="textSecondary">
            {t(`service`)+(language.includes("en")?"06:00 pm on 28 Jan 2021":language.includes("CN")?"2021年1月28日晚上06:00":"2021年1月28日晚上06:00")+t(`service2`)}
          </Typography> */}
          <Typography variant="subtitle1" style={{lineHeight: "normal", marginBottom:"10px"}} color="textSecondary">
          {t(`service`)+(language.includes("en")?`${endTime} on ${englishDate}`:language.includes("CN")?`${chineseDate}${endTime}`:`${chineseDate}${endTime}`)+t(`service2`)}
          </Typography>
          <Typography variant="subtitle1" style={{lineHeight: "normal"}} color="textSecondary">
            {t(`service3`)}
          </Typography>
        </CardContent>
      </div>
      
            </Card>
        </Paper>

	);
}