/** @format */

import React, { useRef, useEffect, useState } from "react";
import { ChatStatusBar } from "./ChatStatusBar/ChatStatusBar";
import { ChatMessages } from "./ChatMessages/ChatMessages";
import { ChatInput } from "./ChatInput/ChatInput";
import { Box, makeStyles, Container, Typography, Paper } from "@material-ui/core";
import { useTypedSelector } from "../../reducers";
import { useLocation, useParams } from "react-router-dom";

import Cookies from "universal-cookie";

import { useDispatch } from "react-redux";

import { preventBehavior, onTabClose } from "../Utils/PreventScroll";
import { useTranslation } from "react-i18next";

import "./type.d";

interface IChatProps {}

const useStyles = makeStyles((theme) => ({
	adminBar: {},
	userBar: {
		position: "fixed",
		width: "100%",
		zIndex: 1200,
	},
	adminInput: {},
	userInput: {
		position: "fixed",
		width: "100%",
		bottom: 0,
		zIndex: 1000,
	},
	adminMessages: { overflowY: "scroll", overflowX: "hidden" },
	userMessages: {
		overflowX: "hidden",
		marginTop: "25px",
		marginBottom: "41px",
		flexGrow: 1,
		height: "calc(100vh - 66px)",
	},
	callButton: {
		zIndex: 5,
		position: "absolute",
		right: "4.2rem",
		top: "0.55rem",
		"@media (max-width: 600px)": {
			top: "0.3rem",
		},
	},
	userContainer: {
		// overflowY: "hidden",
		position: "relative",
		minHeight: "-webkit-fill-available" || "100%",
		height: "-webkit-fill-available" || "100%",
		backgroundColor: "#eaeaea",
		//display: "grid",
		//gridTemplateRows: `auto 0.5fr auto`,
		//height: `100vh`,
	},
	adminContainer: {
		overflowY: "hidden",
		position: "relative",
		backgroundColor: "#eaeaea",
		height: "90vh",
	},
	watermark1: {
		position: "fixed",
		left: "50%",
		top: "33%",
		width: "100%",
		textAlign: "center",
		transform: "translate(-50%, -50%)",
		zIndex: 1000,
		opacity: 0.5,
		fontSize: "5rem",
		[theme.breakpoints.down("md")]: {
			fontSize: "2rem",
		},
		pointerEvents: "none",
	},
	watermark2: {
		position: "fixed",
		left: "50%",
		top: "66%",
		width: "100%",
		textAlign: "center",
		transform: "translate(-50%, -50%)",
		zIndex: 1000,
		opacity: 0.5,
		fontSize: "5rem",
		[theme.breakpoints.down("md")]: {
			fontSize: "2rem",
		},
		pointerEvents: "none",
	},
}));

export const Chat: React.FC<IChatProps> = (props) => {
	const location = useLocation();
	const classes = useStyles();
	const dispatch = useDispatch();

	const { t } = useTranslation();

	useEffect(() => {
		document.removeEventListener("touchmove", preventBehavior);

	}, []);

	return (
		<Box display="flex" flexDirection="column" className={location.pathname.substring(1, 8) === `service` ? classes.adminContainer : classes.userContainer}>
			<Box className={location.pathname.substring(1, 8) === `service` ? classes.adminBar : classes.userBar}>
				<ChatStatusBar />
			</Box>

			<Box className={location.pathname.substring(1, 8) === `service` ? classes.adminMessages : classes.userMessages}>
				<ChatMessages />
			</Box>

			<Box className={location.pathname.substring(1, 8) === `service` ? classes.adminInput : classes.userInput}>
				<ChatInput />
			</Box>
		</Box>
	);
};
