import { IMessage, MediaType, IUnknown } from './../models/message';
import { addMessage, addChar, getMessagesPending, getMessages, addMessagePending, addMessageSuccess, addMessageError, appendMessageToMessagesList, getMessagesSuccess, getMessagesError, addCharPending, addCharSuccess, addCharError, emptyMessages, emptyMessagesPending, emptyMessagesSuccess, emptyMessagesError, addFail } from './../actions/message';
import { Socket as SocketValue } from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { take, put, fork, call, all, takeLatest } from 'redux-saga/effects';

type Socket = typeof SocketValue;

function subscribe (socket: Socket) {
	return eventChannel((emit) => {
		// event listener here...
		socket.on('onAddedMessage', ({data, error}: {
			data: IMessage,
			error: string[],
		}) => {
			emit(appendMessageToMessagesList({
				message: {
					...data,
					type: MediaType.TEXT,
				},
			}));
		});

		socket.on('onAddedUnknown', ({data, error}: {
			data: IUnknown,
			error: string[],
		}) => {
			emit(addFail({
				input: data.originalInput
			}))
			emit(appendMessageToMessagesList({
				message: {
					...data,
					type: MediaType.UNKNOWN,
				},
			}));
		});

		return () => {}
	});
}

function* read (socket: Socket) {
	const channel = yield call(subscribe, socket);

	while (true) {
		const action = yield take(channel);
		yield put(action);
	}
}

function* appendMessage (action: any) {
	yield put(appendMessageToMessagesList({
		message: {
			id: Date.now(),
			userId: "",
			createdAt: Date.now(),
			type: MediaType.TEXT,
			...action.payload
		}
	}));
}


function* write (socket: Socket) {
	yield takeLatest(getMessages, function* (action) {
		yield put(getMessagesPending());

		try {
			const { texts} = yield all({
				texts: call(() => {
					socket.emit('getMessagesByRoomId', action.payload);

					return new Promise((res, rej) => {
						socket.on('getMessagesByRoomIdResponse', ({data, error}: {
							data: {
								messages: IMessage[]
							},
							error: string[]
						}) => {
							socket.off('getMessagesByRoomIdResponse');
							if (error) {
								rej(error);
							} else {
								res(data.messages.map(x => ({...x, type: MediaType.TEXT})));
							}
						});
					});
				}),

			});

			yield put(getMessagesSuccess({
				roomId: action.payload.roomId,
				messages: [...texts].sort((a, b) => parseInt(a.createdAt) - parseInt(b.createdAt)),
			}));
		} catch (e) {
			yield put(getMessagesError());
		}
	});

	yield takeLatest(addMessage, function* (action) {
		yield put(addMessagePending());

		socket.emit('addMessage', action.payload);

		yield put(addMessageSuccess());
	});

	yield takeLatest(addChar, function* (action) {
		yield put(addCharPending());

		socket.emit('addChar', action.payload);

		const { data, error } = yield call(() => {
			return new Promise((res, rej) => {
				socket.on('addCharResponse', (payload: any) => {
					socket.off('addCharResponse');
					res(payload);
				});
			});
		});
				
		if (error) {
			yield put(addCharError());
		} else {
			yield put(addCharSuccess({
				suggestions: data.result
			}));
		}
	});

	yield takeLatest(emptyMessages, function* (action) {
		yield put(emptyMessagesPending());

		try {
			const { texts } = yield all({
				texts: call(() => {
					socket.emit('emptyMessagesByRoomId', action.payload);

					return new Promise((res, rej) => {
						socket.on('emptyMessagesByRoomIdResponse', ({data, error}: {
							data: {
								messages: IMessage[]
							},
							error: string[]
						}) => {
							socket.off('emptyMessagesByRoomIdResponse');
							if (error) {
								rej(error);
							} else {
								res();
							}
						});
					});
				}),
			});

			yield put(emptyMessagesSuccess({
				roomId: action.payload.roomId,
			}));
		} catch (e) {
			yield put(emptyMessagesError());
		}
	});
}

export function* handleMessageAction(socket: Socket) {
	yield fork(read, socket);
	yield fork(write, socket);
}